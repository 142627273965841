import { Button, Progress } from '@mantine/core'
import axios from 'axios'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AdminVideo } from '../../api/schema'
import VideosAPI, { ACCEPTED_VIDEO_TYPES, Video } from '../../api/videos'
import routes from '../../config/routes'
import getVideoContentType from '../../helpers/getVideoContentType'
import FileInput from './utils/file-input'
import FormButtonGroup from './utils/form-button-group'
import FormGroup from './utils/form-group'

export default function VideoForm({ video }: { video?: AdminVideo }) {
  const navigate = useNavigate()
  const [uploading, setUploading] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)
  const [name, setName] = useState(video?.name || '')
  const [nameErrors, setNameErrors] = useState<string[]>([])
  const [filename, setFilename] = useState(video?.filename || '')
  const [filenameErrors, setFilenameErrors] = useState<string[]>([])
  const [duration, setDuration] = useState(video?.duration || '')
  const [durationErrors, setDurationErrors] = useState<string[]>([])
  const [file, setFile] = useState<File | null>(null)
  const [contentTypeError, setContentTypeError] = useState(false)
  const formId = video ? 'edit-video-form' : 'new-video-form'

  const hasChanges = video ? name !== video.name || file : true

  return (
    <form
      id={formId}
      onSubmit={async event => {
        event.preventDefault()
        setUploading(true)

        if (file && !getVideoContentType(file)) setContentTypeError(true)
        else {
          try {
            let newVideo: Video
            if (video) {
              newVideo = await VideosAPI.update(video.id, {
                name,
                filename,
                fetchNewUploadUrl: !!file,
                contentType: getVideoContentType(file),
                duration,
              })
            } else {
              newVideo = await VideosAPI.create({
                contentType: getVideoContentType(file),
                filename,
                name,
                duration,
              })
            }

            if (file) {
              await axios.put(newVideo.uploadUrl!, file, {
                headers: {
                  'Content-Type': file?.type,
                  // make sure not to send normal auth token, since it is unrelated to this api
                  // and can create authorization issues
                  Authorization: null,
                },
                onUploadProgress: event => {
                  setUploadProgress(event.progress! * 100)
                },
              })
            }

            navigate(routes.app.videos.show(newVideo.id))
          } catch (err: any) {
            if (Array.isArray(err.name)) setNameErrors(err.name)
            if (Array.isArray(err.filename)) setFilenameErrors(err.filename)
            if (Array.isArray(err.duration)) setDurationErrors(err.duration)
          }
        }
      }}
    >
      <FormGroup
        required
        id="name"
        label="name"
        name="name"
        value={name}
        errors={nameErrors}
        onChange={val => {
          setName(val)
          setNameErrors([])
        }}
      />

      <FormGroup
        required
        id="filename"
        label="filename"
        name="filename"
        value={filename}
        errors={filenameErrors}
        onChange={val => {
          setFilename(val)
          setFilenameErrors([])
        }}
      />

      <FormGroup
        required
        id="duration"
        label="duration"
        name="duration"
        value={duration}
        errors={durationErrors}
        onChange={val => {
          setDuration(val)
          setDurationErrors([])
        }}
      />

      {contentTypeError && <div>The content type of the file you selected must be either mp4 or m4v</div>}
      <FileInput
        addFileText="Add video"
        changeFileText="Change video"
        accept={ACCEPTED_VIDEO_TYPES}
        onChange={file => setFile(file)}
      ></FileInput>

      {uploading && <Progress my={40} value={uploadProgress} />}

      <FormButtonGroup>
        <Button className="filled green" disabled={!hasChanges || uploading} type="submit">
          {video ? 'Save' : 'Create'}
        </Button>
      </FormButtonGroup>
    </form>
  )
}
