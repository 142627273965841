import { Anchor, Button, Paper } from '@mantine/core'
import TitleWithButton from '../title/TitleWithButton'
import { ChecklistTrigger } from '../../api/supportChecklistTriggers'
import { ReactNode, useState } from 'react'
import EmptyMessage from '../display/empty-message'
import SelectChecklistTriggerModal from '../modal/composite/select-support-checklist-trigger'
import { Link } from 'react-router-dom'
import ChecklistTriggerCard from '../card/support-checklist-trigger-card'
import { AdminSupportTriggerableItemChecklistTrigger } from '../../api/schema'

export default function TriggerableItemChecklistTriggersCrud({
  triggerableItemChecklistTriggers,
  onSelectChecklistTrigger,
  triggerableItemChecklistTriggerShowRoute,
  emptyMessage = 'No event triggers found',
}: {
  triggerableItemChecklistTriggers: AdminSupportTriggerableItemChecklistTrigger[]
  onSelectChecklistTrigger: (eventTrigger: ChecklistTrigger) => void | Promise<void>
  triggerableItemChecklistTriggerShowRoute: (
    eventTrigger: AdminSupportTriggerableItemChecklistTrigger,
  ) => string
  emptyMessage?: ReactNode
}) {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <Paper mt={20} p={20} className="checklist-triggers-list">
        <TitleWithButton mb={40} order={2} title="Checklist Triggers">
          <Button
            className="green filled add"
            onClick={() => {
              setShowModal(true)
            }}
          >
            Add checklist trigger
          </Button>
        </TitleWithButton>

        {!triggerableItemChecklistTriggers.length && <EmptyMessage>{emptyMessage}</EmptyMessage>}
        {triggerableItemChecklistTriggers.map((triggerableItemChecklistTrigger, index) => (
          <Anchor
            key={index}
            component={Link}
            to={triggerableItemChecklistTriggerShowRoute(triggerableItemChecklistTrigger)}
          >
            <ChecklistTriggerCard
              supportChecklistTrigger={triggerableItemChecklistTrigger.checklistTrigger}
              key={index}
            />
          </Anchor>
        ))}
      </Paper>

      <SelectChecklistTriggerModal
        opened={showModal}
        selectedSupportChecklistTriggerIds={triggerableItemChecklistTriggers.map(
          triggerableItemChecklistTrigger => triggerableItemChecklistTrigger.checklistTrigger.id,
        )}
        onCancel={() => setShowModal(false)}
        onSelectSupportChecklistTrigger={async checklistTrigger => {
          setShowModal(false)
          await onSelectChecklistTrigger(checklistTrigger)
        }}
      />
    </>
  )
}
