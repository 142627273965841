import { Anchor, Button, Paper, Table, Text } from '@mantine/core'
import { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { AdminVideo } from '../../api/schema'
import VideosAPI from '../../api/videos'
import Loader from '../../components/loader'
import TitleWithButton from '../../components/title/TitleWithButton'
import routes from '../../config/routes'
import useAuthEffect from '../../hooks/useAuthEffect'

export default function VideosIndexPage() {
  const [videos, setVideos] = useState<AdminVideo[]>([])
  const [errorFetchingVideo, setErrorFetchingVideo] = useState(false)
  const [doneFetching, setDoneFetching] = useState(false)

  useAuthEffect(() => {
    async function fetchVideos() {
      try {
        const videos = await VideosAPI.index()
        setVideos(videos)
        setDoneFetching(true)
      } catch (error) {
        setErrorFetchingVideo(true)
      }
    }

    // eslint-disable-next-line
    fetchVideos()
  })

  return (
    <div className="page">
      <TitleWithButton title="Videos">
        <Button className="filled green" component={Link} to={routes.app.videos.new}>
          New video
        </Button>
      </TitleWithButton>

      {videos.length > 0 && (
        <Paper mt={20} p="md">
          <Table>
            <thead>
              <tr>
                <td>Name</td>
              </tr>
            </thead>
            <tbody>
              {videos.map((video, index) => (
                <tr key={index}>
                  <td>
                    <Anchor component={NavLink} to={routes.app.videos.show(video.id)}>
                      {video.name}
                    </Anchor>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Paper>
      )}

      {!doneFetching && <Loader />}

      {doneFetching && videos.length === 0 && (
        <Text style={{ textAlign: 'center' }}>
          <Paper m="lg" p="md">
            No videos have been created yet.{' '}
            <Anchor component={Link} to={routes.app.videos.new}>
              Create one
            </Anchor>{' '}
            to get started.
          </Paper>
        </Text>
      )}
    </div>
  )
}
