import { Anchor, Card, Group, Text } from '@mantine/core'
import { Link } from 'react-router-dom'
import { AdminLessonResource } from '../../api/schema'

export default function LessonResourceCard({
  lessonResource,
  onClick,
  href,
}: {
  lessonResource: AdminLessonResource
  href?: string
  onClick?: () => void
}) {
  const card = (
    <Card onClick={onClick} mb={10} padding="sm" radius="md" withBorder>
      <Group mt="md" mb="xs">
        {href ? (
          <Anchor component={Link} to={href}>
            <Text style={{ fontWeight: 500, marginLeft: 'auto' }}>{lessonResource.name}</Text>
          </Anchor>
        ) : (
          <Text style={{ fontWeight: 500, marginLeft: 'auto' }}>{lessonResource.name}</Text>
        )}
      </Group>
      <Text style={{ marginLeft: 'auto' }} size="xs" color="gray">
        Approximate minutes: {lessonResource.approximateMinutes}
      </Text>
    </Card>
  )
  return onClick ? <button onClick={onClick}>{card}</button> : card
}
