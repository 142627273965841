import { Anchor, Breadcrumbs, Button } from '@mantine/core'
import { useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { AdminSupport, AdminSupportTextCoachTip, AdminSupportVideoCoachTip } from '../../../api/schema'
import SupportCoachTipsAPI from '../../../api/supportCoachTips'
import SupportsAPI from '../../../api/supports'
import AddOrEditHumanCoach from '../../../components/add-or-edit-human-coach'
import AddOrEditVideo from '../../../components/add-or-edit-video'
import FormLocalizationSection from '../../../components/forms/form-localization-section'
import Loader from '../../../components/loader'
import SelectHumanCoachModal from '../../../components/modal/composite/select-human-coach'
import SelectVideoModal from '../../../components/modal/composite/select-video'
import ConfirmModal from '../../../components/modal/confirm'
import TitleWithButton from '../../../components/title/TitleWithButton'
import routes from '../../../config/routes'
import useAuthEffect from '../../../hooks/useAuthEffect'

export type SupportCoachTipShowModalType = 'delete' | 'add_video' | 'add_human_coach' | null

export default function SupportCoachTipsShowPage() {
  const params = useParams()
  const navigate = useNavigate()
  const [support, setSupport] = useState<AdminSupport | null>(null)
  const [coachTip, setCoachTip] = useState<AdminSupportTextCoachTip | AdminSupportVideoCoachTip | null>(null)
  const [errorFetchingSupport, setErrorFetchingSupport] = useState(false)
  const [modalType, setModalType] = useState<SupportCoachTipShowModalType>(null)
  const id = params.id!
  const supportId = params.supportId!

  async function fetchSupport() {
    try {
      const support = await SupportsAPI.show(supportId)
      setSupport(support)
      setCoachTip(support.coachTips.find((coachTip: any) => coachTip.id === id) || null)
    } catch (error) {
      setErrorFetchingSupport(true)
    }
  }

  useAuthEffect(() => {
    // eslint-disable-next-line
    fetchSupport()
  })

  if (errorFetchingSupport) return <div>There was an error fetching the support you requested.</div>
  if (!support) return <Loader />
  if (!coachTip) return <div>The coach tip you are looking for was not found on this support.</div>

  return (
    <div className="page">
      <Breadcrumbs>
        <Anchor component={Link} to={routes.app.supports.index}>
          supports
        </Anchor>
        <Anchor component={Link} to={routes.app.supports.show(support.id)}>
          {support.name}
        </Anchor>
        <Anchor component={Link} to={routes.app.supports.coachTips.show(support.id, id)}>
          {coachTip.name}
        </Anchor>
      </Breadcrumbs>

      <TitleWithButton title={coachTip.name}>
        <Button
          style={{ marginRight: 10 }}
          component={Link}
          to={routes.app.supports.coachTips.edit(supportId, id)}
          variant="outline"
        >
          edit coach tip
        </Button>
        <Button onClick={() => setModalType('delete')} color="red" variant="outline">
          delete
        </Button>
      </TitleWithButton>

      <AddOrEditHumanCoach
        humanCoach={coachTip.humanCoach || null}
        onAddOrEditClick={async () => {
          setModalType('add_human_coach')
        }}
        onRemoveClick={async () => {
          try {
            await SupportCoachTipsAPI.update(coachTip.id, { humanCoachId: null })
            await fetchSupport()
          } catch (err) {
            // TODO: handle this
          }
        }}
      />

      <FormLocalizationSection
        apiModule={SupportCoachTipsAPI}
        existingLocalizedTexts={coachTip.localizedTexts}
        localizableId={coachTip.id}
        extraArgs={{ supportId: support.id }}
        onChange={async newLocale => {
          await fetchSupport()
        }}
      />

      {coachTip.type === 'SupportVideoCoachTip' && (
        <AddOrEditVideo
          video={(coachTip as AdminSupportVideoCoachTip).video}
          onAddOrEditClick={async () => {
            setModalType('add_video')
          }}
          onRemoveClick={async () => {
            try {
              await SupportCoachTipsAPI.update(coachTip.id, { videoId: null })
              await fetchSupport()
            } catch (err) {
              // TODO: handle this
            }
          }}
        />
      )}

      <ConfirmModal
        opened={modalType === 'delete'}
        onCancel={() => setModalType(null)}
        onConfirm={async () => {
          await SupportCoachTipsAPI.delete(coachTip.id)
          navigate(routes.app.supports.show(support.id))
        }}
        title="Are you sure?"
        message="Are you sure you want to delete this coaching tip?"
        confirmText="Really delete"
      />

      <SelectVideoModal
        opened={modalType === 'add_video'}
        onCancel={() => setModalType(null)}
        onSelectVideo={async video => {
          setModalType(null)
          try {
            await SupportCoachTipsAPI.update(coachTip.id, { videoId: video.id })
            await fetchSupport()
          } catch (err) {
            // TODO: handle this
          }
        }}
      />

      <SelectHumanCoachModal
        opened={modalType === 'add_human_coach'}
        onCancel={() => setModalType(null)}
        onSelectHumanCoach={async humanCoach => {
          setModalType(null)
          try {
            await SupportCoachTipsAPI.update(coachTip.id, { humanCoachId: humanCoach.id })
            await fetchSupport()
          } catch (err) {
            // TODO: handle this
          }
        }}
      />
    </div>
  )
}
