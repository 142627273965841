import { Modal, Text, TextInput } from '@mantine/core'
import { useEffect, useState } from 'react'
import ArticlesAPI from '../../../api/articles'
import { AdminArticle } from '../../../api/schema'
import ArticleCard from '../../card/article-card'

export default function SelectArticleModal({
  opened,
  selectedArticleIds,
  onCancel,
  onSelectArticle,
  title = 'Select a lesson resource',
}: {
  opened: boolean
  selectedArticleIds: string[]
  onSelectArticle: (article: AdminArticle) => void
  onCancel: () => void
  title?: string
  confirmText?: string
  cancelText?: string
}) {
  const [query, setQuery] = useState('')
  const [articles, setArticles] = useState<AdminArticle[]>([])

  useEffect(() => {
    async function fetchArticles() {
      setArticles(await ArticlesAPI.index({ query }))
    }
    // eslint-disable-next-line
    fetchArticles()
  }, [query])

  const filteredArticles = articles.filter(article => !selectedArticleIds.includes(article.id))

  return (
    <Modal fullScreen opened={opened} onClose={onCancel} title={<h3>{title}</h3>}>
      <Text size={'md'} mt={20} mb={20}>
        Select an article
      </Text>
      <TextInput
        id="search-articles-input"
        mb={20}
        value={query}
        onChange={e => setQuery(e.target.value)}
        label="Search"
      />
      {filteredArticles.map((article, index) => (
        <button
          key={index}
          onClick={async () => {
            onSelectArticle(article)
          }}
        >
          <ArticleCard article={article} />
        </button>
      ))}
    </Modal>
  )
}
