import { Anchor, Breadcrumbs, Button, Paper, Table } from '@mantine/core'
import { useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import ArticlesAPI from '../../api/articles'
import { AdminArticle } from '../../api/schema'
import AddOrEditImage from '../../components/add-or-edit-image'
import FormLocalizationSection from '../../components/forms/form-localization-section'
import Loader from '../../components/loader'
import SelectImageModal from '../../components/modal/composite/select-image'
import ConfirmModal from '../../components/modal/confirm'
import TitleWithButton from '../../components/title/TitleWithButton'
import routes from '../../config/routes'
import useAuthEffect from '../../hooks/useAuthEffect'

type ArticlesShowModalType = 'delete' | 'add_image' | null

export default function ArticlesShowPage({ articleId }: { articleId?: string } = {}) {
  const params = useParams()
  const navigate = useNavigate()
  const [article, setArticle] = useState<AdminArticle | null>(null)
  const [errorFetchingArticle, setErrorFetchingArticle] = useState(false)
  const [modalType, setModalType] = useState<ArticlesShowModalType>(null)
  const id = articleId || params.id!

  async function fetchArticle(id: string) {
    try {
      const article = await ArticlesAPI.show(id)
      setArticle(article)
    } catch (error) {
      setErrorFetchingArticle(true)
    }
  }

  useAuthEffect(() => {
    // eslint-disable-next-line
    fetchArticle(id)
  })

  if (errorFetchingArticle) return <div>There was an error fetching the article you requested.</div>
  if (!article) return <Loader />

  return (
    <div className="page">
      <Breadcrumbs>
        <Anchor component={Link} to={routes.app.articles.index}>
          articles
        </Anchor>
        <Anchor component={Link} to={routes.app.articles.new}>
          {article.name}
        </Anchor>
      </Breadcrumbs>

      <TitleWithButton title={article.name}>
        <Button
          className="outlined blue"
          style={{ marginRight: 10 }}
          component={Link}
          to={routes.app.articles.edit(article.id)}
        >
          edit
        </Button>
        <Button
          className="outlined blue"
          component={Link}
          to={article.url}
          target="_blank"
          style={{ marginRight: 10 }}
        >
          preview
        </Button>
        <Button className="outlined red" onClick={() => setModalType('delete')}>
          delete
        </Button>
      </TitleWithButton>

      <AddOrEditImage
        image={article.image || null}
        onAddOrEditClick={async () => {
          setModalType('add_image')
        }}
        onRemoveClick={async () => {
          try {
            await ArticlesAPI.update(article.id, { imageId: null })
            await fetchArticle(article.id)
          } catch (err) {
            // TODO: handle this
          }
        }}
      />

      <Paper p={20} mt={20}>
        <Table>
          <tbody>
            {[
              <tr key={1}>
                <td>Url:</td>
                <td style={{ textAlign: 'right' }}>
                  <Anchor target="_blank" component={Link} to={article.url}>
                    {article.url}
                  </Anchor>
                </td>
              </tr>,
              <tr>
                <td>Source:</td>
                <td style={{ textAlign: 'right' }}>{article.source}</td>
              </tr>,
            ]}
          </tbody>
        </Table>
      </Paper>

      <FormLocalizationSection
        apiModule={ArticlesAPI}
        existingLocalizedTexts={article.localizedTexts}
        localizableId={article.id}
        onChange={async _ => {
          await fetchArticle(article.id)
        }}
        excludedFields={['description', 'summaryDescription']}
      />

      <ConfirmModal
        opened={modalType === 'delete'}
        onCancel={() => setModalType(null)}
        onConfirm={async () => {
          await ArticlesAPI.delete(article.id)
          navigate(routes.app.articles.index)
        }}
        title="Are you sure?"
        message="Are you sure you want to delete this article?"
        confirmText="Really delete"
      />

      <SelectImageModal
        opened={modalType === 'add_image'}
        onCancel={() => setModalType(null)}
        onSelectImage={async image => {
          setModalType(null)
          try {
            await ArticlesAPI.update(article.id, { imageId: image.id })
            await fetchArticle(article.id)
          } catch (err) {
            // TODO: handle this
          }
        }}
      />
    </div>
  )
}
