import routes from '../config/routes'
import { api } from './common'
import { Image } from './images'
import { LocalizedText, LocalizedTextParams } from './localizedTexts'
import { AdminArticle } from './schema'

export default class ArticlesAPI {
  public static async create(attrs: ArticleParams) {
    return await api.post(routes.api.articles.create, { article: attrs })
  }

  public static async update(id: string, attrs: Partial<ArticleParams>) {
    return await api.patch(routes.api.articles.update(id), { article: attrs })
  }

  public static async delete(id: string) {
    return await api.delete(routes.api.articles.delete(id))
  }

  public static async index({ query }: { query?: string } = {}) {
    const res = await api.get(routes.api.articles.index, { params: { query } })
    return res.data as AdminArticle[]
  }

  public static async show(id: string) {
    const res = await api.get(routes.api.articles.show(id))
    return res.data as AdminArticle
  }

  public static async createLocalizedText(articleId: string, params: LocalizedTextParams) {
    return await api.post(routes.api.articles.localizedTexts.create(articleId), { localizedText: params })
  }
}

export interface Article {
  id: string
  name: string
  url: string
  localizedTexts: LocalizedText[]
  image?: Image
  source?: string
}

export interface ArticleParams {
  name: string
  url?: string
  imageId?: string | null
  source?: string | null
}
