import routes from '../config/routes'
import { api } from './common'
import { Image } from './images'
import { AdminHumanCoach } from './schema'

export default class HumanCoachesAPI {
  public static async create(attrs: HumanCoachParams) {
    return await api.post(routes.api.humanCoaches.create, { humanCoach: attrs })
  }

  public static async update(id: string, attrs: Partial<HumanCoachParams>) {
    return await api.patch(routes.api.humanCoaches.update(id), { humanCoach: attrs })
  }

  public static async delete(id: string) {
    return await api.delete(routes.api.humanCoaches.delete(id))
  }

  public static async index({ query }: { query?: string } = {}) {
    const res = await api.get(routes.api.humanCoaches.index, { params: { query } })
    return res.data as AdminHumanCoach[]
  }

  public static async show(id: string) {
    const res = await api.get(routes.api.humanCoaches.show(id))
    return res.data as AdminHumanCoach
  }
}

export interface HumanCoach {
  id: string
  name: string
  image: Image | null
  imageId: string | null
}

export interface HumanCoachParams {
  name: string
  imageId?: string | null
}
