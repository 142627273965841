import { Button } from '@mantine/core'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ArticlesAPI from '../../api/articles'
import { AdminArticle } from '../../api/schema'
import routes from '../../config/routes'
import FormButtonGroup from './utils/form-button-group'
import FormGroup from './utils/form-group'

export default function ArticleForm({ article }: { article?: AdminArticle }) {
  const navigate = useNavigate()
  const [name, setName] = useState('')
  const [nameErrors, setNameErrors] = useState<string[]>([])
  const [url, setUrl] = useState('')
  const [urlErrors, setUrlErrors] = useState<string[]>([])
  const [source, setSource] = useState('')
  const [sourceErrors, setSourceErrors] = useState<string[]>([])

  const formId = article ? 'edit-article-form' : 'new-article-form'
  const hasChanges = article
    ? name !== article.name || url !== article.url || source !== article.source
    : true

  return (
    <form
      id={formId}
      onSubmit={async event => {
        event.preventDefault()

        try {
          if (article) {
            await ArticlesAPI.update(article.id, {
              name,
              url,
              source,
            })
            navigate(routes.app.articles.show(article.id))
          } else {
            const res = await ArticlesAPI.create({
              name,
              url,
              source,
            })
            navigate(routes.app.articles.show(res.data))
          }
        } catch (err: any) {
          if (Array.isArray(err.name)) setNameErrors(err.name)
          if (Array.isArray(err.url)) setUrlErrors(err.url)
          if (Array.isArray(err.source)) setSourceErrors(err.source)
        }
      }}
    >
      <FormGroup
        required
        id="name"
        label="name"
        name="name"
        value={name}
        errors={nameErrors}
        onChange={val => {
          setName(val)
          setNameErrors([])
        }}
      />

      <FormGroup
        required
        id="url"
        inputType="text"
        label="url"
        name="url"
        value={url}
        errors={urlErrors}
        onChange={val => {
          setUrl(val)
          setUrlErrors([])
        }}
      />

      <FormGroup
        required
        id="source"
        inputType="text"
        label="source"
        name="source"
        value={source}
        errors={sourceErrors}
        onChange={val => {
          setSource(val)
          setSourceErrors([])
        }}
      />

      <FormButtonGroup>
        <Button className="filled green" disabled={!hasChanges} type="submit">
          {article ? 'Save' : 'Create'}
        </Button>
      </FormButtonGroup>
    </form>
  )
}
