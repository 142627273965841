import { Button, Card } from '@mantine/core'
import { useState } from 'react'
import { JourneyOnboardingQuestion } from '../../api/journeyOnboardingQuestions'
import JourneysAPI from '../../api/journeys'
import ConfirmModal from '../modal/confirm'
import TitleWithButton from '../title/TitleWithButton'

type JourneyOnboardingQuestionsCrudModalType = 'delete' | null

export default function JourneyOnboardingQuestionCard({
  journeyId,
  journeyOnboardingQuestion,
  onDelete,
}: {
  journeyId: string
  journeyOnboardingQuestion: JourneyOnboardingQuestion
  onDelete: (journeyOnboardingQuestion: JourneyOnboardingQuestion) => void | Promise<void>
}) {
  const [modalType, setModalType] = useState<JourneyOnboardingQuestionsCrudModalType>(null)

  const card = (
    <Card
      className={`journey-question-card ${journeyOnboardingQuestion.screen}`}
      mb={10}
      padding="sm"
      radius="md"
      withBorder
    >
      <TitleWithButton mb={10} title={journeyOnboardingQuestion.screen.replace(/_/g, ' ')} order={5}>
        <Button
          className="red outlined delete"
          onClick={() => {
            setModalType('delete')
          }}
        >
          delete
        </Button>
      </TitleWithButton>

      <ConfirmModal
        opened={modalType === 'delete'}
        onCancel={() => setModalType(null)}
        onConfirm={async () => {
          await JourneysAPI.deleteOnboardingQuestion(journeyId, journeyOnboardingQuestion.id)
          await onDelete(journeyOnboardingQuestion)
        }}
        title="Are you sure?"
        message={`Are you sure you want to delete this onboarding question?`}
        confirmText="Really delete"
      />
    </Card>
  )
  return card
}
