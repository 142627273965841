import { Button, Card } from '@mantine/core'
import { useState } from 'react'
import { PracticeOnboardingQuestion } from '../../api/practiceOnboardingQuestions'
import PracticesAPI from '../../api/practices'
import ConfirmModal from '../modal/confirm'
import TitleWithButton from '../title/TitleWithButton'

type PracticeOnboardingQuestionsCrudModalType = 'delete' | null

export default function PracticeOnboardingQuestionCard({
  practiceId,
  practiceOnboardingQuestion,
  onDelete,
}: {
  practiceId: string
  practiceOnboardingQuestion: PracticeOnboardingQuestion
  onDelete: (practiceOnboardingQuestion: PracticeOnboardingQuestion) => void | Promise<void>
}) {
  const [modalType, setModalType] = useState<PracticeOnboardingQuestionsCrudModalType>(null)

  const card = (
    <Card
      className={`practice-question-card ${practiceOnboardingQuestion.screen}`}
      mb={10}
      padding="sm"
      radius="md"
      withBorder
    >
      <TitleWithButton title={practiceOnboardingQuestion.screen.replace(/_/g, ' ')} order={5}>
        <Button
          className="delete"
          color="red"
          variant="outline"
          onClick={() => {
            setModalType('delete')
          }}
        >
          delete
        </Button>
      </TitleWithButton>

      <ConfirmModal
        opened={modalType === 'delete'}
        onCancel={() => setModalType(null)}
        onConfirm={async () => {
          await PracticesAPI.deleteOnboardingQuestion(practiceId, practiceOnboardingQuestion.id)
          await onDelete(practiceOnboardingQuestion)
          setModalType(null)
        }}
        title="Are you sure?"
        message={`Are you sure you want to delete this onboarding question?`}
        confirmText="Really delete"
      />
    </Card>
  )
  return card
}
