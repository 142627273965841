import { Button, Progress } from '@mantine/core'
import axios from 'axios'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ImagesAPI, { ACCEPTED_IMAGE_TYPES, Image } from '../../api/images'
import { AdminImage } from '../../api/schema'
import routes from '../../config/routes'
import getImageContentType from '../../helpers/getImageContentType'
import FileInput from './utils/file-input'
import FormButtonGroup from './utils/form-button-group'
import FormGroup from './utils/form-group'

export default function ImageForm({ image }: { image?: AdminImage }) {
  const navigate = useNavigate()
  const [uploading, setUploading] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)
  const [name, setName] = useState(image?.name || '')
  const [nameErrors, setNameErrors] = useState<string[]>([])
  const [filename, setFilename] = useState(image?.filename || '')
  const [filenameErrors, setFilenameErrors] = useState<string[]>([])
  const [contentTypeError, setContentTypeError] = useState(false)
  const [file, setFile] = useState<File | null>(null)
  const formId = image ? 'edit-image-form' : 'new-image-form'

  const hasChanges = image ? name !== image.name || file : true

  return (
    <form
      id={formId}
      onSubmit={async event => {
        event.preventDefault()
        setUploading(true)

        if (file && !getImageContentType(file)) setContentTypeError(true)
        else {
          try {
            let savedImage: Image
            if (image) {
              savedImage = await ImagesAPI.update(image.id, {
                name,
                filename,
                fetchNewUploadUrl: !!file,
                contentType: getImageContentType(file),
              })
            } else {
              savedImage = await ImagesAPI.create({
                name,
                filename,
                contentType: getImageContentType(file!),
              })
            }

            if (file) {
              await axios.put(savedImage.uploadUrl!, file, {
                headers: {
                  'Content-Type': file?.type,
                  // make sure not to send normal auth token, since it is unrelated to this api
                  // and can create authorization issues
                  Authorization: null,
                },
                onUploadProgress: event => {
                  setUploadProgress(event.progress! * 100)
                },
              })
            }

            navigate(routes.app.images.show(savedImage.id))
          } catch (err: any) {
            if (Array.isArray(err.name)) setNameErrors(err.name)
            if (Array.isArray(err.filename)) setFilenameErrors(err.filename)
          }
        }
      }}
    >
      <FormGroup
        required
        id="name"
        label="name"
        name="name"
        value={name}
        errors={nameErrors}
        onChange={val => {
          setName(val)
          setNameErrors([])
        }}
      />

      <FormGroup
        required
        id="filename"
        label="filename"
        name="filename"
        value={filename}
        errors={filenameErrors}
        onChange={val => {
          setFilename(val)
          setFilenameErrors([])
        }}
      />

      {contentTypeError && <div>The content type of the file you selected must be either jpg or png</div>}
      <FileInput
        addFileText="Add image"
        changeFileText="Change image"
        accept={ACCEPTED_IMAGE_TYPES}
        onChange={file => setFile(file)}
      ></FileInput>

      {uploading && <Progress my={20} value={uploadProgress} />}

      <FormButtonGroup>
        <Button className="filled green" disabled={!hasChanges || uploading} type="submit">
          {image ? 'Save' : 'Create'}
        </Button>
      </FormButtonGroup>
    </form>
  )
}
