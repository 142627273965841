import { Button, Modal, Text } from '@mantine/core'
import {
  PracticeOnboardingQuestionType,
  PracticeOnboardingQuestionTypes,
} from '../../../api/practiceOnboardingQuestions'

export default function SelectPracticeOnboardingScreenModal({
  opened,
  selectedOnboardingQuestionTypes,
  onCancel,
  onSelectOnboardingQuestionType,
  title = 'Select an onboarding question type',
}: {
  opened: boolean
  selectedOnboardingQuestionTypes: PracticeOnboardingQuestionType[]
  onSelectOnboardingQuestionType: (type: PracticeOnboardingQuestionType) => void
  onCancel: () => void
  title?: string
  confirmText?: string
  cancelText?: string
}) {
  const filteredPracticeOnboardingQuestionTypes = PracticeOnboardingQuestionTypes.filter(
    type => !selectedOnboardingQuestionTypes.includes(type as PracticeOnboardingQuestionType),
  )

  return (
    <Modal
      fullScreen
      opened={opened}
      onClose={onCancel}
      title={<h3>{title}</h3>}
      style={{ textAlign: 'center' }}
    >
      <Text size={'md'} mt={200} mb={20} style={{ textAlign: 'center' }}>
        Select an onboarding question type for the new question you want to add to this practice
      </Text>
      {filteredPracticeOnboardingQuestionTypes.map((type, index) => (
        <Button
          className="add-onboarding-question-btn"
          variant="outline"
          key={index}
          m={5}
          onClick={() => onSelectOnboardingQuestionType(type as PracticeOnboardingQuestionType)}
        >
          {type.replace(/_/g, ' ')}
        </Button>
      ))}
    </Modal>
  )
}
