import { Modal, Text, TextInput } from '@mantine/core'
import { useEffect, useState } from 'react'
import PracticesAPI, { AdminPractice, Practice } from '../../../api/practices'
import PracticeCard from '../../card/practice-card'

export default function SelectPracticeModal({
  opened,
  selectedPracticeIds,
  onCancel,
  onSelectPractice,
  title = 'Select a practice',
}: {
  opened: boolean
  selectedPracticeIds: string[]
  onSelectPractice: (practice: AdminPractice) => void | Promise<void>
  onCancel: () => void
  title?: string
  confirmText?: string
  cancelText?: string
}) {
  const [query, setQuery] = useState('')
  const [practices, setPractices] = useState<AdminPractice[]>([])

  useEffect(() => {
    async function fetchPractices() {
      setPractices(await PracticesAPI.index({ query }))
    }
    // eslint-disable-next-line
    fetchPractices()
  }, [query])

  const filteredPractices = practices.filter(practice => !selectedPracticeIds.includes(practice.id))

  return (
    <Modal fullScreen opened={opened} onClose={onCancel} title={<h3>{title}</h3>}>
      <Text size={'md'} mt={20} mb={20}>
        Select a practice to add to your part
      </Text>
      <TextInput
        id="search-practices-input"
        mb={20}
        value={query}
        onChange={e => setQuery(e.target.value)}
        label="Search"
      />
      {filteredPractices.map((practice, index) => (
        <button
          className="add-practice-btn"
          key={index}
          onClick={async () => {
            await onSelectPractice(practice)
          }}
        >
          <PracticeCard practice={practice} />
        </button>
      ))}
    </Modal>
  )
}
