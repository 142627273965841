import { Anchor, Breadcrumbs, Button } from '@mantine/core'
import { useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { AdminSupport, AdminSupportTrackerTip } from '../../../api/schema'
import SupportTrackerTipsAPI from '../../../api/supportTrackerTips'
import SupportsAPI from '../../../api/supports'
import FormLocalizationSection from '../../../components/forms/form-localization-section'
import Loader from '../../../components/loader'
import ConfirmModal from '../../../components/modal/confirm'
import TitleWithButton from '../../../components/title/TitleWithButton'
import routes from '../../../config/routes'
import useAuthEffect from '../../../hooks/useAuthEffect'

export default function SupportTrackerTipsShowPage() {
  const params = useParams()
  const navigate = useNavigate()
  const [support, setSupport] = useState<AdminSupport | null>(null)
  const [trackerTip, setTrackerTip] = useState<AdminSupportTrackerTip | null>(null)
  const [errorFetchingSupport, setErrorFetchingSupport] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const id = params.id!
  const supportId = params.supportId!

  async function fetchSupport() {
    try {
      const support = await SupportsAPI.show(supportId)
      setSupport(support)
      setTrackerTip(support.trackerTips.find(trackerTip => trackerTip.id === id) || null)
    } catch (error) {
      setErrorFetchingSupport(true)
    }
  }

  useAuthEffect(() => {
    // eslint-disable-next-line
    fetchSupport()
  })

  if (errorFetchingSupport) return <div>There was an error fetching the support you requested.</div>
  if (!support) return <Loader />
  if (!trackerTip) return <div>The tracker tip you are looking for was not found on this support.</div>

  return (
    <div className="page">
      <Breadcrumbs>
        <Anchor component={Link} to={routes.app.supports.index}>
          supports
        </Anchor>
        <Anchor component={Link} to={routes.app.supports.show(support.id)}>
          {support.name}
        </Anchor>
        <Anchor component={Link} to={routes.app.supports.trackerTips.show(support.id, id)}>
          {trackerTip.name}
        </Anchor>
      </Breadcrumbs>

      <TitleWithButton title={trackerTip.name}>
        <Button
          style={{ marginRight: 10 }}
          component={Link}
          to={routes.app.supports.trackerTips.edit(supportId, id)}
          variant="outline"
        >
          edit tracker tip
        </Button>
        <Button onClick={() => setShowDeleteModal(true)} color="red" variant="outline">
          delete
        </Button>
      </TitleWithButton>

      <FormLocalizationSection
        apiModule={SupportTrackerTipsAPI}
        existingLocalizedTexts={trackerTip.localizedTexts}
        localizableId={trackerTip.id}
        extraArgs={{ supportId: support.id }}
        onChange={async newLocale => {
          await fetchSupport()
        }}
      />

      <ConfirmModal
        opened={showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
        onConfirm={async () => {
          await SupportTrackerTipsAPI.delete(trackerTip.id)
          navigate(routes.app.supports.show(support.id))
        }}
        title="Are you sure?"
        message="Are you sure you want to delete this tracker tip?"
        confirmText="Really delete"
      />
    </div>
  )
}
