import { Modal, TextInput } from '@mantine/core'
import { useEffect, useState } from 'react'
import SupportChecklistTriggersAPI, {
  ChecklistTrigger as SupportChecklistTrigger,
} from '../../../api/supportChecklistTriggers'
import SupportChecklistTriggerCard from '../../card/support-checklist-trigger-card'

export default function SelectSupportChecklistTriggerModal({
  opened,
  selectedSupportChecklistTriggerIds,
  onCancel,
  onSelectSupportChecklistTrigger,
  title = 'Select a checklist trigger',
}: {
  opened: boolean
  selectedSupportChecklistTriggerIds: string[]
  onSelectSupportChecklistTrigger: (supportChecklistTrigger: SupportChecklistTrigger) => void | Promise<void>
  onCancel: () => void
  title?: string
  confirmText?: string
  cancelText?: string
}) {
  const [query, setQuery] = useState('')
  const [supportChecklistTriggers, setSupportChecklistTriggers] = useState<SupportChecklistTrigger[]>([])

  useEffect(() => {
    async function fetchSupportChecklistTriggers() {
      setSupportChecklistTriggers(await SupportChecklistTriggersAPI.index({ query }))
    }
    // eslint-disable-next-line
    fetchSupportChecklistTriggers()
  }, [query])

  const filteredSupportChecklistTriggers = supportChecklistTriggers.filter(
    supportChecklistTrigger => !selectedSupportChecklistTriggerIds.includes(supportChecklistTrigger.id),
  )

  return (
    <Modal fullScreen opened={opened} onClose={onCancel} title={<h3>{title}</h3>}>
      <TextInput
        id="search-support-checklist-triggers-input"
        mb={20}
        value={query}
        onChange={e => setQuery(e.target.value)}
        label="Search"
      />
      {filteredSupportChecklistTriggers.map((supportChecklistTrigger, index) => (
        <button
          className="add-checklist-trigger-btn"
          key={index}
          onClick={async () => {
            await onSelectSupportChecklistTrigger(supportChecklistTrigger)
          }}
        >
          <SupportChecklistTriggerCard supportChecklistTrigger={supportChecklistTrigger} />
        </button>
      ))}
    </Modal>
  )
}
