import { Anchor, Button, Paper, Table, Text } from '@mantine/core'
import { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import LessonsAPI from '../../api/lessons'
import { AdminLesson } from '../../api/schema'
import Loader from '../../components/loader'
import TitleWithButton from '../../components/title/TitleWithButton'
import routes from '../../config/routes'
import useAuthEffect from '../../hooks/useAuthEffect'

export default function LessonsIndexPage() {
  const [lessons, setLessons] = useState<AdminLesson[]>([])
  const [errorFetchingLesson, setErrorFetchingLesson] = useState(false)
  const [doneFetching, setDoneFetching] = useState(false)

  useAuthEffect(() => {
    async function fetchLessons() {
      try {
        const lessons = await LessonsAPI.index()
        setLessons(lessons)
        setDoneFetching(true)
      } catch (error) {
        setErrorFetchingLesson(true)
      }
    }
    // eslint-disable-next-line
    fetchLessons()
  })

  return (
    <div className="page">
      <TitleWithButton title="Lessons">
        <Button className="filled green" component={Link} to={routes.app.lessons.new}>
          New lesson
        </Button>
      </TitleWithButton>

      {lessons.length > 0 && (
        <Paper mt={20} p="md">
          <Table>
            <thead>
              <tr>
                <td>Name</td>
              </tr>
            </thead>
            <tbody>
              {lessons.map((lesson, index) => (
                <tr key={index}>
                  <td>
                    <Anchor component={NavLink} to={routes.app.lessons.show(lesson.id)}>
                      {lesson.name}
                    </Anchor>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Paper>
      )}

      {!doneFetching && <Loader />}

      {doneFetching && lessons.length === 0 && (
        <Text style={{ textAlign: 'center' }}>
          <Paper m="lg" p="md">
            No lessons have been created yet.{' '}
            <Anchor component={Link} to={routes.app.lessons.new}>
              Create one
            </Anchor>{' '}
            to get started.
          </Paper>
        </Text>
      )}
    </div>
  )
}
