import { Anchor, Breadcrumbs, Button, Title } from '@mantine/core'
import { useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import PhasesAPI from '../../api/phases'
import { AdminPhase } from '../../api/schema'
import FormButtonGroup from '../../components/forms/utils/form-button-group'
import FormGroup from '../../components/forms/utils/form-group'
import Loader from '../../components/loader'
import routes from '../../config/routes'
import useAuthEffect from '../../hooks/useAuthEffect'

export default function PhasesEditPage() {
  const params = useParams()
  const navigate = useNavigate()
  const [name, setName] = useState('')
  const [nameErrors, setNameErrors] = useState<string[]>([])
  const [foundation, setFoundation] = useState(false)
  const [foundationErrors, setFoundationErrors] = useState<string[]>([])
  const [phase, setPhase] = useState<AdminPhase | null>(null)
  const [errorFetchingPhase, setErrorFetchingPhase] = useState(false)

  const hasChanges = phase && (name !== phase.name || foundation !== phase.foundation)

  useAuthEffect(() => {
    async function fetchPhase(id: string) {
      try {
        const phase = await PhasesAPI.show(id)
        setPhase(phase)
        setName(phase.name)
        setFoundation(phase.foundation)
      } catch (error) {
        setErrorFetchingPhase(true)
      }
    }

    // eslint-disable-next-line
    fetchPhase(params.id!)
  })

  if (!phase) return <Loader />

  return (
    <div className="page">
      <Breadcrumbs>
        <Anchor component={Link} to={routes.app.phases.index}>
          phases
        </Anchor>
        <Anchor component={Link} to={routes.app.phases.show(phase.id)}>
          {phase.name}
        </Anchor>
        <Anchor component={Link} to={routes.app.phases.edit(phase.id)}>
          edit
        </Anchor>
      </Breadcrumbs>

      <Title>Edit {phase.name}</Title>
      <form
        id="edit-phase-form"
        onSubmit={async event => {
          event.preventDefault()
          try {
            await PhasesAPI.update(phase.id, {
              name,
              foundation,
            })
            navigate(routes.app.phases.show(phase.id))
          } catch (err: any) {
            if (Array.isArray(err.name)) setNameErrors(err.name)
            if (Array.isArray(err.foundation)) setFoundationErrors(err.foundation)
          }
        }}
      >
        <FormGroup
          required
          id="name"
          label="name"
          name="name"
          value={name}
          errors={nameErrors}
          onChange={val => {
            setName(val)
            setNameErrors([])
          }}
        />
        <FormGroup
          id="foundation"
          inputType="switch"
          label="Foundation"
          name="foundation"
          value={foundation}
          errors={foundationErrors}
          onChange={val => {
            setFoundation(val)
            setFoundationErrors([])
          }}
        />

        <FormButtonGroup>
          <Button disabled={!hasChanges} type="submit">
            Save
          </Button>
        </FormButtonGroup>
      </form>
    </div>
  )
}
