import { Button, Paper } from '@mantine/core'
import { ReactNode, useState } from 'react'
import {
  JourneyOnboardingQuestion,
  JourneyOnboardingQuestionType,
} from '../../api/journeyOnboardingQuestions'
import JourneyOnboardingQuestionCard from '../card/journey-onboarding-question-card'
import EmptyMessage from '../display/empty-message'
import SelectJourneyOnboardingScreenModal from '../modal/composite/select-journey-onboarding-screen-modal'
import SortableItems from '../sortable-items'
import TitleWithButton from '../title/TitleWithButton'

type JourneyOnboardingQuestionsCrudModalType = 'create' | 'delete' | null

export default function JourneyOnboardingQuestionsCrud({
  journeyId,
  journeyOnboardingQuestions,
  onSelectOnboardingQuestion,
  onDeleteOnboardingQuestion,
  onSort,
  emptyMessage = 'No onboarding questions found',
}: {
  journeyId: string
  journeyOnboardingQuestions: JourneyOnboardingQuestion[]
  onSelectOnboardingQuestion: (onboardingQuestionType: JourneyOnboardingQuestionType) => void | Promise<void>
  onDeleteOnboardingQuestion: (OnboardingQuestion: JourneyOnboardingQuestion) => void | Promise<void>
  onSort: (
    journeyOnboardingQuestion: JourneyOnboardingQuestion,
    position: number,
    newJourneyOnboardingQuestions: JourneyOnboardingQuestion[],
  ) => void | Promise<void>
  emptyMessage?: ReactNode
}) {
  const [modalType, setModalType] = useState<JourneyOnboardingQuestionsCrudModalType>(null)
  const [onboardingQuestionToDelete, setOnboardingQuestionToDelete] =
    useState<JourneyOnboardingQuestion | null>(null)

  return (
    <>
      <Paper mt={20} p={20} className="onboarding-questions-list">
        <TitleWithButton order={2} title="Onboarding Questions">
          <Button className="green filled" onClick={() => setModalType('create')}>
            Add question
          </Button>
        </TitleWithButton>

        {!journeyOnboardingQuestions.length && <EmptyMessage>{emptyMessage}</EmptyMessage>}

        <SortableItems
          itemType="JourneyOnboardingQuestion"
          items={journeyOnboardingQuestions}
          onSort={async (journeyOnboardingQuestion, position, newJourneyOnboardingQuestions) => {
            await onSort(journeyOnboardingQuestion, position, newJourneyOnboardingQuestions)
          }}
          render={onboardingQuestion => (
            <JourneyOnboardingQuestionCard
              journeyId={journeyId}
              journeyOnboardingQuestion={onboardingQuestion}
              onDelete={async onboardingQuestion => {
                await onDeleteOnboardingQuestion(onboardingQuestionToDelete!)
              }}
            />
          )}
        />
      </Paper>

      <SelectJourneyOnboardingScreenModal
        opened={modalType === 'create'}
        selectedOnboardingQuestionTypes={journeyOnboardingQuestions.map(question => question.screen)}
        onCancel={() => setModalType(null)}
        onSelectOnboardingQuestionType={async journeyOnboardingQuestionType => {
          setModalType(null)
          await onSelectOnboardingQuestion(journeyOnboardingQuestionType)
        }}
      />
    </>
  )
}
