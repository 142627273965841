import routes from '../config/routes'
import { api } from './common'
import { Image } from './images'
import { LocalizedText, LocalizedTextParams } from './localizedTexts'
import { AdminLessonResource } from './schema'

export default class LessonResourcesAPI {
  public static async create(attrs: LessonResourceParams) {
    return await api.post(routes.api.lessonResources.create, { lessonResource: attrs })
  }

  public static async update(id: string, attrs: Partial<LessonResourceParams>) {
    return await api.patch(routes.api.lessonResources.update(id), { lessonResource: attrs })
  }

  public static async delete(id: string) {
    return await api.delete(routes.api.lessonResources.delete(id))
  }

  public static async index({ query }: { query?: string } = {}) {
    const res = await api.get(routes.api.lessonResources.index, { params: { query } })
    return res.data as AdminLessonResource[]
  }

  public static async show(id: string) {
    const res = await api.get(routes.api.lessonResources.show(id))
    return res.data as AdminLessonResource
  }

  public static async createLocalizedText(lessonResourceId: string, params: LocalizedTextParams) {
    return await api.post(routes.api.lessonResources.localizedTexts.create(lessonResourceId), {
      localizedText: params,
    })
  }
}

export interface LessonResource {
  id: string
  name: string
  externalId: string
  url: string
  approximateMinutes: number
  imageId: string | null
  image: Image | null
  localizedTexts: LocalizedText[]
}

export interface LessonResourceParams {
  name: string
  approximateMinutes: number
  imageId?: string | null
  externalId?: string | null
}
