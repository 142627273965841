import { Button, Paper } from '@mantine/core'
import { AdminImage } from '../api/schema'
import ImageCard from './card/image-card'

export default function AddOrEditImage({
  image,
  onAddOrEditClick,
  onRemoveClick,
  emptyMessage = 'no image set',
  landscape = false,
  addText = 'Add image',
  editText = 'Edit image',
  removeText = 'Remove image',
}: {
  image: AdminImage | null
  onAddOrEditClick?: () => void | Promise<void>
  onRemoveClick?: () => void | Promise<void>
  landscape?: boolean
  emptyMessage?: string
  addText?: string
  editText?: string
  removeText?: string
}) {
  const addOrEditImageButton = (
    <Button
      className="outlined blue"
      style={{ marginRight: 10 }}
      fullWidth
      onClick={async () => {
        await onAddOrEditClick?.()
      }}
    >
      {image ? editText : addText}
    </Button>
  )

  return image ? (
    <div style={{ textAlign: 'center', display: 'inline-block', marginLeft: 10, marginRight: 10 }}>
      <ImageCard image={image} width={landscape ? 500 : 300} height={300}>
        <>
          {addOrEditImageButton}
          <Button
            className="red outlined"
            fullWidth
            mt={10}
            onClick={async () => {
              await onRemoveClick?.()
            }}
          >
            {removeText}
          </Button>
        </>
      </ImageCard>
    </div>
  ) : (
    <Paper mt={20} pt={100} pb={100} pl={150} pr={150} style={{ textAlign: 'center' }}>
      <p>{emptyMessage}</p>
      {addOrEditImageButton}
    </Paper>
  )
}
