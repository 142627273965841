import { Anchor, Button, Paper, Table, Text } from '@mantine/core'
import { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import ArticlesAPI from '../../api/articles'
import { AdminArticle } from '../../api/schema'
import Loader from '../../components/loader'
import TitleWithButton from '../../components/title/TitleWithButton'
import routes from '../../config/routes'
import useAuthEffect from '../../hooks/useAuthEffect'

export default function ArticlesIndexPage() {
  const [articles, setArticles] = useState<AdminArticle[]>([])
  const [errorFetchingArticle, setErrorFetchingArticle] = useState(false)
  const [doneFetching, setDoneFetching] = useState(false)

  useAuthEffect(() => {
    async function fetchArticles() {
      try {
        const articles = await ArticlesAPI.index()
        setArticles(articles)
        setDoneFetching(true)
      } catch (error) {
        setErrorFetchingArticle(true)
      }
    }

    // eslint-disable-next-line
    fetchArticles()
  })

  return (
    <div className="page">
      <TitleWithButton title="Articles">
        <Button className="filled green" component={Link} to={routes.app.articles.new}>
          New article
        </Button>
      </TitleWithButton>

      {articles.length > 0 && (
        <Paper mt={20} p="md">
          <Table>
            <thead>
              <tr>
                <td>Name</td>
                <td>Url</td>
              </tr>
            </thead>
            <tbody>
              {articles.map((article, index) => (
                <tr key={index}>
                  <td>
                    <Anchor component={NavLink} to={routes.app.articles.show(article.id)}>
                      {article.name}
                    </Anchor>
                  </td>
                  <td>{article.url}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Paper>
      )}

      {!doneFetching && <Loader />}

      {doneFetching && articles.length === 0 && (
        <Text style={{ textAlign: 'center' }}>
          <Paper m="lg" p="md">
            No articles have been created yet.{' '}
            <Anchor component={Link} to={routes.app.articles.new}>
              Create one
            </Anchor>{' '}
            to get started.
          </Paper>
        </Text>
      )}
    </div>
  )
}
