import { Modal, Text, TextInput } from '@mantine/core'
import { useEffect, useState } from 'react'
import PartsAPI, { Part } from '../../../api/parts'
import { AdminPart } from '../../../api/schema'
import PartCard from '../../card/part-card'

export default function SelectPartModal({
  opened,
  selectedPartIds,
  onCancel,
  onSelectPart,
  title = 'Select a part',
}: {
  opened: boolean
  selectedPartIds: string[]
  onSelectPart: (part: AdminPart) => void
  onCancel: () => void
  title?: string
  confirmText?: string
  cancelText?: string
}) {
  const [query, setQuery] = useState('')
  const [parts, setParts] = useState<AdminPart[]>([])

  useEffect(() => {
    async function fetchParts() {
      setParts(await PartsAPI.index({ query }))
    }

    // eslint-disable-next-line
    fetchParts()
  }, [query])

  const filteredParts = parts.filter(part => !selectedPartIds.includes(part.id))

  return (
    <Modal fullScreen opened={opened} onClose={onCancel} title={<h3>{title}</h3>}>
      <Text size={'md'} mt={20} mb={20}>
        Select a part to add to your phase
      </Text>
      <TextInput
        id="search-parts-input"
        mb={20}
        value={query}
        onChange={e => setQuery(e.target.value)}
        label="Search"
      />
      {filteredParts.map((part, index) => (
        <button
          className="add-part-btn"
          key={index}
          onClick={() => {
            onSelectPart(part)
          }}
        >
          <PartCard part={part} />
        </button>
      ))}
    </Modal>
  )
}
