import { Anchor, Button, Paper } from '@mantine/core'
import TitleWithButton from '../title/TitleWithButton'
import { EventTrigger } from '../../api/supportEventTriggers'
import { ReactNode, useState } from 'react'
import EmptyMessage from '../display/empty-message'
import SelectEventTriggerModal from '../modal/composite/select-support-event-trigger'
import { Link, useNavigate } from 'react-router-dom'
import EventTriggerCard from '../card/support-event-trigger-card'
import { ChecklistTriggerDesiredStatesEnum } from '../../api/supportChecklistTriggers'
import { ChecklistTriggerEventTrigger } from '../../api/supportChecklistTriggerEventTriggers'

export default function ChecklistTriggerEventTriggersCrud({
  matchEventTriggers,
  noMatchEventTriggers,
  onSelectEventTrigger,
  eventTriggerShowRouteCB,
  emptyMessage = 'No event triggers found',
}: {
  matchEventTriggers: ChecklistTriggerEventTrigger[]
  noMatchEventTriggers: ChecklistTriggerEventTrigger[]
  onSelectEventTrigger: (
    eventTrigger: EventTrigger,
    desiredState: ChecklistTriggerDesiredStatesEnum,
  ) => void | Promise<void>
  eventTriggerShowRouteCB: (eventTrigger: ChecklistTriggerEventTrigger) => string
  emptyMessage?: ReactNode
}) {
  const [showModal, setShowModal] = useState(false)
  const [desiredState, setDesiredState] = useState<ChecklistTriggerDesiredStatesEnum | null>(null)

  return (
    <>
      <Paper mt={20} p={20} className="match-event-triggers-list">
        <TitleWithButton mb={40} order={2} title="Match Event Triggers">
          <Button
            className="green filled add"
            onClick={() => {
              setDesiredState('match')
              setShowModal(true)
            }}
          >
            Add match event trigger
          </Button>
        </TitleWithButton>

        {!matchEventTriggers.length && <EmptyMessage>{emptyMessage}</EmptyMessage>}
        {matchEventTriggers.map((checklistTriggerEventTrigger, index) => (
          <Anchor key={index} component={Link} to={eventTriggerShowRouteCB(checklistTriggerEventTrigger)}>
            <EventTriggerCard supportEventTrigger={checklistTriggerEventTrigger.eventTrigger} key={index} />
          </Anchor>
        ))}
      </Paper>

      <Paper mt={20} p={20} className="no-match-event-triggers-list">
        <TitleWithButton mb={40} order={2} title="No Match Event Triggers">
          <Button
            className="green filled add"
            onClick={() => {
              setDesiredState('no-match')
              setShowModal(true)
            }}
          >
            Add no-match event trigger
          </Button>
        </TitleWithButton>

        {!noMatchEventTriggers.length && <EmptyMessage>{emptyMessage}</EmptyMessage>}
        {noMatchEventTriggers.map((checklistTriggerEventTrigger, index) => (
          <Anchor key={index} component={Link} to={eventTriggerShowRouteCB(checklistTriggerEventTrigger)}>
            <EventTriggerCard supportEventTrigger={checklistTriggerEventTrigger.eventTrigger} key={index} />
          </Anchor>
        ))}
      </Paper>

      <SelectEventTriggerModal
        opened={showModal}
        selectedSupportEventTriggerIds={[
          ...matchEventTriggers.map(
            checklistTriggerEventTrigger => checklistTriggerEventTrigger.eventTrigger.id,
          ),
          ...noMatchEventTriggers.map(
            checklistTriggerEventTrigger => checklistTriggerEventTrigger.eventTrigger.id,
          ),
        ]}
        onCancel={() => setShowModal(false)}
        onSelectSupportEventTrigger={async eventTrigger => {
          setShowModal(false)
          await onSelectEventTrigger(eventTrigger, desiredState!)
        }}
      />
    </>
  )
}
