import { Anchor, Breadcrumbs, Button } from '@mantine/core'
import { useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import HumanCoachesAPI from '../../api/humanCoaches'
import { AdminHumanCoach } from '../../api/schema'
import AddOrEditImage from '../../components/add-or-edit-image'
import Loader from '../../components/loader'
import SelectImageModal from '../../components/modal/composite/select-image'
import ConfirmModal from '../../components/modal/confirm'
import TitleWithButton from '../../components/title/TitleWithButton'
import routes from '../../config/routes'
import useAuthEffect from '../../hooks/useAuthEffect'

type HumanCoachesShowModalType = 'delete' | 'add_image' | null

export default function HumanCoachesShowPage({ humanCoachId }: { humanCoachId?: string } = {}) {
  const params = useParams()
  const navigate = useNavigate()
  const [humanCoach, setHumanCoach] = useState<AdminHumanCoach | null>(null)
  const [errorFetchingHumanCoach, setErrorFetchingHumanCoach] = useState(false)
  const [modalType, setModalType] = useState<HumanCoachesShowModalType>(null)
  const id = humanCoachId || params.id!

  async function fetchHumanCoach(id: string) {
    try {
      const humanCoach = await HumanCoachesAPI.show(id)
      setHumanCoach(humanCoach)
    } catch (error) {
      setErrorFetchingHumanCoach(true)
    }
  }

  useAuthEffect(() => {
    // eslint-disable-next-line
    fetchHumanCoach(id)
  })

  if (errorFetchingHumanCoach) return <div>There was an error fetching the humanCoach you requested.</div>
  if (!humanCoach) return <Loader />

  return (
    <div className="page">
      <Breadcrumbs>
        <Anchor component={Link} to={routes.app.humanCoaches.index}>
          human coaches
        </Anchor>
        <Anchor component={Link} to={routes.app.humanCoaches.new}>
          {humanCoach.name}
        </Anchor>
      </Breadcrumbs>

      <TitleWithButton title={humanCoach.name}>
        <Button
          className="blue outlined"
          style={{ marginRight: 10 }}
          component={Link}
          to={routes.app.humanCoaches.edit(humanCoach.id)}
        >
          edit
        </Button>
        <Button className="red outlined" onClick={() => setModalType('delete')}>
          delete
        </Button>
      </TitleWithButton>

      <AddOrEditImage
        image={humanCoach.image}
        onAddOrEditClick={() => {
          setModalType('add_image')
        }}
        onRemoveClick={async () => {
          try {
            await HumanCoachesAPI.update(humanCoach.id, { imageId: null })
            await fetchHumanCoach(humanCoach.id)
          } catch (err) {
            // TODO: handle this
          }
        }}
      />

      <ConfirmModal
        opened={modalType === 'delete'}
        onCancel={() => setModalType(null)}
        onConfirm={async () => {
          await HumanCoachesAPI.delete(humanCoach.id)
          navigate(routes.app.humanCoaches.index)
        }}
        title="Are you sure?"
        message="Are you sure you want to delete this human coach?"
        confirmText="Really delete"
      />

      <SelectImageModal
        opened={modalType === 'add_image'}
        onCancel={() => setModalType(null)}
        onSelectImage={async image => {
          setModalType(null)
          try {
            await HumanCoachesAPI.update(humanCoach.id, { imageId: image.id })
            await fetchHumanCoach(humanCoach.id)
          } catch (err) {
            // TODO: handle this
          }
        }}
      />
    </div>
  )
}
