import { Anchor, Breadcrumbs, Button } from '@mantine/core'
import { useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import LessonResourcesAPI from '../../../api/lessonResources'
import { AdminLessonResource, AdminSupport } from '../../../api/schema'
import SupportsAPI from '../../../api/supports'
import Loader from '../../../components/loader'
import ConfirmModal from '../../../components/modal/confirm'
import TitleWithButton from '../../../components/title/TitleWithButton'
import routes from '../../../config/routes'
import useAuthEffect from '../../../hooks/useAuthEffect'

export default function SupportLessonResourcesShowPage() {
  const params = useParams()
  const navigate = useNavigate()
  const [support, setSupport] = useState<AdminSupport | null>(null)
  const [errorFetchingLesson, setErrorFetchingLesson] = useState(false)
  const [lessonResource, setLessonResource] = useState<AdminLessonResource | null>(null)
  const [errorFetchingLessonResource, setErrorFetchingLessonResource] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  async function fetchLessonResource(id: string) {
    try {
      const lessonResource = await LessonResourcesAPI.show(id)
      setLessonResource(lessonResource)
    } catch (error) {
      setErrorFetchingLessonResource(true)
    }
  }

  async function fetchSupport(id: string) {
    try {
      const support = await SupportsAPI.show(id)
      setSupport(support)
    } catch (error) {
      setErrorFetchingLesson(true)
    }
  }

  useAuthEffect(() => {
    // eslint-disable-next-line
    fetchLessonResource(params.lessonResourceId!)
    // eslint-disable-next-line
    fetchSupport(params.id!)
  })

  if (errorFetchingLessonResource)
    return <div>There was an error fetching the lessonResource you requested.</div>
  if (errorFetchingLesson) return <div>There was an error fetching the lesson you requested.</div>
  if (!lessonResource || !support) return <Loader />

  return (
    <div className="page">
      <Breadcrumbs>
        <Anchor component={Link} to={routes.app.supports.index}>
          lessons
        </Anchor>
        <Anchor component={Link} to={routes.app.supports.show(support.id)}>
          {support.name}
        </Anchor>
        <Anchor component={Link} to={routes.app.lessonResources.show(lessonResource.id)}>
          {lessonResource.name}
        </Anchor>
      </Breadcrumbs>

      <TitleWithButton title={lessonResource.name}>
        <Button onClick={() => setShowDeleteModal(true)} color="red" variant="outline">
          detach from support
        </Button>
      </TitleWithButton>

      <ConfirmModal
        opened={showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
        onConfirm={async () => {
          await SupportsAPI.detachLessonResource(support.id, lessonResource.id)
          navigate(routes.app.supports.show(support.id))
        }}
        title="Are you sure?"
        message="Are you sure you want to detach this lesson resource from this support?"
        confirmText="Really detach?"
      />
    </div>
  )
}
