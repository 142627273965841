import { Modal, TextInput } from '@mantine/core'
import { useEffect, useState } from 'react'
import SupportEventTriggersAPI, {
  EventTrigger as SupportEventTrigger,
} from '../../../api/supportEventTriggers'
import SupportEventTriggerCard from '../../card/support-event-trigger-card'

export default function SelectSupportEventTriggerModal({
  opened,
  selectedSupportEventTriggerIds,
  onCancel,
  onSelectSupportEventTrigger,
  title = 'Select an event trigger',
}: {
  opened: boolean
  selectedSupportEventTriggerIds: string[]
  onSelectSupportEventTrigger: (supportEventTrigger: SupportEventTrigger) => void | Promise<void>
  onCancel: () => void
  title?: string
  confirmText?: string
  cancelText?: string
}) {
  const [query, setQuery] = useState('')
  const [supportEventTriggers, setSupportEventTriggers] = useState<SupportEventTrigger[]>([])

  useEffect(() => {
    async function fetchSupportEventTriggers() {
      setSupportEventTriggers(await SupportEventTriggersAPI.index({ query }))
    }
    // eslint-disable-next-line
    fetchSupportEventTriggers()
  }, [query])

  const filteredSupportEventTriggers = supportEventTriggers.filter(
    supportEventTrigger => !selectedSupportEventTriggerIds.includes(supportEventTrigger.id),
  )

  return (
    <Modal fullScreen opened={opened} onClose={onCancel} title={<h3>{title}</h3>}>
      <TextInput
        id="search-support-event-modifiers-input"
        mb={20}
        value={query}
        onChange={e => setQuery(e.target.value)}
        label="Search"
      />
      {filteredSupportEventTriggers.map((supportEventTrigger, index) => (
        <button
          className="add-support-event-trigger-btn"
          key={index}
          onClick={async () => {
            await onSelectSupportEventTrigger(supportEventTrigger)
          }}
        >
          <SupportEventTriggerCard supportEventTrigger={supportEventTrigger} />
        </button>
      ))}
    </Modal>
  )
}
