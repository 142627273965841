import { Anchor, Breadcrumbs, Button } from '@mantine/core'
import { useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import LessonsAPI from '../../../api/lessons'
import PartsAPI from '../../../api/parts'
import { AdminLesson, AdminPart } from '../../../api/schema'
import Loader from '../../../components/loader'
import ConfirmModal from '../../../components/modal/confirm'
import TitleWithButton from '../../../components/title/TitleWithButton'
import routes from '../../../config/routes'
import useAuthEffect from '../../../hooks/useAuthEffect'

export default function PartLessonsShowPage() {
  const params = useParams()
  const navigate = useNavigate()
  const [part, setPart] = useState<AdminPart | null>(null)
  const [errorFetchingPart, setErrorFetchingPart] = useState(false)
  const [lesson, setLesson] = useState<AdminLesson | null>(null)
  const [errorFetchingLesson, setErrorFetchingLesson] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  async function fetchLesson(id: string) {
    try {
      const lesson = await LessonsAPI.show(id)
      setLesson(lesson)
    } catch (error) {
      setErrorFetchingLesson(true)
    }
  }

  async function fetchPart(id: string) {
    try {
      const part = await PartsAPI.show(id)
      setPart(part)
    } catch (error) {
      setErrorFetchingPart(true)
    }
  }

  useAuthEffect(() => {
    // eslint-disable-next-line
    fetchLesson(params.lessonId!)
    // eslint-disable-next-line
    fetchPart(params.id!)
  })

  if (errorFetchingLesson) return <div>There was an error fetching the lesson you requested.</div>
  if (errorFetchingPart) return <div>There was an error fetching the part you requested.</div>
  if (!lesson || !part) return <Loader />

  return (
    <div className="page">
      <Breadcrumbs>
        <Anchor component={Link} to={routes.app.parts.index}>
          parts
        </Anchor>
        <Anchor component={Link} to={routes.app.parts.show(part.id)}>
          {part.name}
        </Anchor>
        <Anchor component={Link} to={routes.app.lessons.new}>
          {lesson.name}
        </Anchor>
      </Breadcrumbs>

      <TitleWithButton title={lesson.name}>
        <Button onClick={() => setShowDeleteModal(true)} color="red" variant="outline">
          detach from part
        </Button>
      </TitleWithButton>

      <ConfirmModal
        opened={showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
        onConfirm={async () => {
          await PartsAPI.detachLesson(part.id, lesson.id)
          navigate(routes.app.parts.show(part.id))
        }}
        title="Are you sure?"
        message="Are you sure you want to detach this lesson from this part?"
        confirmText="Really detach?"
      />
    </div>
  )
}
