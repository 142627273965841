import { Anchor, Card, Group, Text } from '@mantine/core'
import { Link } from 'react-router-dom'
import { AdminSupportTrackerTip } from '../../api/schema'

export default function SupportTrackerTipCard({
  trackerTip,
  href,
}: {
  trackerTip: AdminSupportTrackerTip
  onClick?: () => void
  href?: string
}) {
  return (
    <Card className={`tracker-tip-card-${trackerTip.id}`} mb={10} padding="sm" radius="md" withBorder>
      <Group mt="md" mb="xs">
        {href ? (
          <Anchor component={Link} to={href}>
            <Text style={{ fontWeight: 500, marginLeft: 'auto' }}>{trackerTip.name}</Text>
          </Anchor>
        ) : (
          <Text style={{ fontWeight: 500, marginLeft: 'auto' }}>{trackerTip.name}</Text>
        )}
      </Group>
    </Card>
  )
}
