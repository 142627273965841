import { Anchor, Breadcrumbs, Button, Title } from '@mantine/core'
import { useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { AdminSupport, AdminSupportTrackerTip } from '../../../api/schema'
import SupportTrackerTipsAPI from '../../../api/supportTrackerTips'
import SupportsAPI from '../../../api/supports'
import FormButtonGroup from '../../../components/forms/utils/form-button-group'
import FormGroup from '../../../components/forms/utils/form-group'
import Loader from '../../../components/loader'
import routes from '../../../config/routes'
import useAuthEffect from '../../../hooks/useAuthEffect'

export default function SupportTrackerTipsEditPage() {
  const params = useParams()
  const navigate = useNavigate()
  const [name, setName] = useState('')
  const [nameErrors, setNameErrors] = useState<string[]>([])
  const [support, setSupport] = useState<AdminSupport | null>(null)
  const [errorFetchingSupport, setErrorFetchingSupport] = useState(false)
  const [trackerTip, setTrackerTip] = useState<AdminSupportTrackerTip | null>(null)

  const hasChanges = support && name !== support.name

  useAuthEffect(() => {
    async function fetchSupport(id: string) {
      try {
        const data = await SupportsAPI.show(id)
        const trackerTip = data.trackerTips.find(ct => ct.id === params.id) || null
        setSupport(data)
        setTrackerTip(trackerTip)
        setName(trackerTip?.name || '')
      } catch (error) {
        setErrorFetchingSupport(true)
      }
    }

    // eslint-disable-next-line
    fetchSupport(params.supportId!)
  })

  if (errorFetchingSupport) return <div>Failed to find the support you're looking for.</div>
  if (!support) return <Loader />
  if (!trackerTip) return <div>The tracker tip you are looking for was not found on this support.</div>

  return (
    <div className="page">
      <Breadcrumbs>
        <Anchor component={Link} to={routes.app.supports.index}>
          supports
        </Anchor>
        <Anchor component={Link} to={routes.app.supports.show(support.id)}>
          {support.name}
        </Anchor>
        <Anchor aria-disabled component={Link} to={routes.app.supports.show(support.id)}>
          tracker tips
        </Anchor>
        <Anchor component={Link} to={routes.app.supports.trackerTips.show(support.id, trackerTip.id)}>
          {trackerTip.name}
        </Anchor>
        <Anchor component={Link} to={routes.app.supports.trackerTips.edit(support.id, trackerTip.id)}>
          edit
        </Anchor>
      </Breadcrumbs>

      <Title>Edit {trackerTip.name}</Title>
      <form
        id="edit-support-tracker-tip-form"
        onSubmit={async event => {
          event.preventDefault()
          try {
            await SupportTrackerTipsAPI.update(trackerTip.id, {
              name,
            })
            navigate(routes.app.supports.trackerTips.show(support.id, trackerTip.id))
          } catch (err: any) {
            if (Array.isArray(err.name)) setNameErrors(err.name)
          }
        }}
      >
        <FormGroup
          required
          id="name"
          label="name"
          name="name"
          value={name}
          errors={nameErrors}
          onChange={val => {
            setName(val)
            setNameErrors([])
          }}
        />

        <FormButtonGroup>
          <Button disabled={!hasChanges} type="submit">
            Save
          </Button>
        </FormButtonGroup>
      </form>
    </div>
  )
}
