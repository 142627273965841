import { Anchor, Breadcrumbs, Button, Title } from '@mantine/core'
import { useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import PartsAPI from '../../api/parts'
import { AdminPart } from '../../api/schema'
import FormButtonGroup from '../../components/forms/utils/form-button-group'
import FormGroup from '../../components/forms/utils/form-group'
import Loader from '../../components/loader'
import routes from '../../config/routes'
import useAuthEffect from '../../hooks/useAuthEffect'

export default function PartsEditPage() {
  const params = useParams()
  const navigate = useNavigate()
  const [name, setName] = useState('')
  const [nameErrors, setNameErrors] = useState<string[]>([])
  const [approximateMinutes, setApproximateMinutes] = useState(1)
  const [approximateMinuteErrors, setApproximateMinuteErrors] = useState<string[]>([])
  const [part, setPart] = useState<AdminPart | null>(null)
  const [errorFetchingPart, setErrorFetchingPart] = useState(false)

  const hasChanges = part && (name !== part.name || approximateMinutes !== part.approximateMinutes)

  useAuthEffect(() => {
    async function fetchPart(id: string) {
      try {
        const part = await PartsAPI.show(id)
        setPart(part)
        setName(part.name)
        setApproximateMinutes(part.approximateMinutes)
      } catch (error) {
        setErrorFetchingPart(true)
      }
    }
    // eslint-disable-next-line
    fetchPart(params.id!)
  })

  if (!part) return <Loader />

  return (
    <div className="page">
      <Breadcrumbs>
        <Anchor component={Link} to={routes.app.parts.index}>
          parts
        </Anchor>
        <Anchor component={Link} to={routes.app.parts.show(part.id)}>
          {part.name}
        </Anchor>
        <Anchor component={Link} to={routes.app.parts.edit(part.id)}>
          edit
        </Anchor>
      </Breadcrumbs>

      <Title>Edit {part.name}</Title>
      <form
        id="edit-part-form"
        onSubmit={async event => {
          event.preventDefault()
          try {
            await PartsAPI.update(part.id, {
              name,
              approximateMinutes,
            })
            navigate(routes.app.parts.show(part.id))
          } catch (err: any) {
            if (Array.isArray(err.name)) setNameErrors(err.name)
            if (Array.isArray(err.approximate_minutes)) setApproximateMinuteErrors(err.approximate_minutes)
          }
        }}
      >
        <FormGroup
          required
          id="name"
          label="name"
          name="name"
          value={name}
          errors={nameErrors}
          onChange={val => {
            setName(val)
            setNameErrors([])
          }}
        />
        <FormGroup
          required
          id="approximate-minutes"
          inputType="number"
          label="Approximate minutes"
          name="approximateMinutes"
          value={approximateMinutes}
          errors={approximateMinuteErrors}
          onChange={val => {
            setApproximateMinutes(val)
            setApproximateMinuteErrors([])
          }}
        />

        <FormButtonGroup>
          <Button disabled={!hasChanges} type="submit">
            Save
          </Button>
        </FormButtonGroup>
      </form>
    </div>
  )
}
