import { Card, Group, Text } from '@mantine/core'
import { AdminArticle } from '../../api/schema'

export default function ArticleCard({ article, onClick }: { article: AdminArticle; onClick?: () => void }) {
  const card = (
    <Card onClick={onClick} mb={10} padding="sm" radius="md" withBorder>
      <Group mt="md" mb="xs">
        <Text style={{ fontWeight: 500 }}>{article.name}</Text>
      </Group>
      <Text style={{ marginLeft: 'auto' }} size="xs" color="gray">
        url: {article.url}
      </Text>
    </Card>
  )
  return onClick ? <button onClick={onClick}>{card}</button> : card
}
