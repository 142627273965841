import routes from '../config/routes'
import { api } from './common'
import { Image } from './images'
import { Lesson } from './lessons'
import { LocalizedText, LocalizedTextParams } from './localizedTexts'
import { AdminPart } from './schema'

export default class PartsAPI {
  public static async create(attrs: PartParams) {
    return await api.post(routes.api.parts.create, { part: attrs })
  }

  public static async update(id: string, attrs: Partial<PartParams>) {
    return await api.patch(routes.api.parts.update(id), { part: attrs })
  }

  public static async delete(id: string) {
    return await api.delete(routes.api.parts.delete(id))
  }

  public static async index({ query }: { query?: string } = {}) {
    const res = await api.get(routes.api.parts.index, { params: { query } })
    return res.data as AdminPart[]
  }

  public static async show(id: string) {
    const res = await api.get(routes.api.parts.show(id))
    return res.data as AdminPart
  }

  public static async attachPractice(partId: string, practiceId: string) {
    return await api.post(routes.api.parts.practices.create(partId), { practiceId })
  }

  public static async detachPractice(partId: string, practiceId: string) {
    return await api.delete(routes.api.parts.practices.delete(partId, practiceId))
  }

  public static async attachLesson(partId: string, lessonId: string) {
    return await api.post(routes.api.parts.lessons.create(partId), { lessonId })
  }

  public static async detachLesson(partId: string, lessonId: string) {
    return await api.delete(routes.api.parts.lessons.delete(partId, lessonId))
  }

  public static async createLocalizedText(partId: string, params: LocalizedTextParams) {
    return await api.post(routes.api.parts.localizedTexts.create(partId), { localizedText: params })
  }
}

export interface Part {
  id: string
  name: string
  approximateMinutes: number
  partLessons: PartLesson[]
  image: Image | null
  imageId: string | null
  localizedTexts: LocalizedText[]
}

export interface PartLesson {
  position: number
  lesson: Lesson
}

export interface PartParams {
  name: string
  approximateMinutes: number
  imageId?: string | null
}
