import { Button, Paper } from '@mantine/core'
import { ReactNode, useState } from 'react'
import {
  PracticeOnboardingQuestion,
  PracticeOnboardingQuestionType,
} from '../../api/practiceOnboardingQuestions'
import PracticeOnboardingQuestionCard from '../card/practice-onboarding-question-card'
import EmptyMessage from '../display/empty-message'
import SelectPracticeOnboardingScreenModal from '../modal/composite/select-practice-onboarding-screen-modal'
import SortableItems from '../sortable-items'
import TitleWithButton from '../title/TitleWithButton'

export default function PracticeOnboardingQuestionsCrud({
  practiceId,
  practiceOnboardingQuestions,
  onSelectOnboardingQuestion,
  onDeleteOnboardingQuestion,
  onSort,
  emptyMessage = 'No onboarding questions found',
}: {
  practiceId: string
  practiceOnboardingQuestions: PracticeOnboardingQuestion[]
  onSelectOnboardingQuestion: (onboardingQuestionType: PracticeOnboardingQuestionType) => void | Promise<void>
  onDeleteOnboardingQuestion: (OnboardingQuestion: PracticeOnboardingQuestion) => void | Promise<void>
  onSort: (
    practiceOnboardingQuestion: PracticeOnboardingQuestion,
    position: number,
    newPracticeOnboardingQuestions: PracticeOnboardingQuestion[],
  ) => void | Promise<void>
  emptyMessage?: ReactNode
}) {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <Paper mt={20} p={20} className="onboarding-questions-list">
        <TitleWithButton order={2} title="Onboarding Questions">
          <Button className="green filled" onClick={() => setShowModal(true)}>
            Add question
          </Button>
        </TitleWithButton>

        {!practiceOnboardingQuestions.length && <EmptyMessage>{emptyMessage}</EmptyMessage>}

        <SortableItems
          itemType="PracticeOnboardingQuestion"
          items={practiceOnboardingQuestions}
          onSort={async (journeyOnboardingQuestion, position, newPracticeOnboardingQuestions) => {
            await onSort(journeyOnboardingQuestion, position, newPracticeOnboardingQuestions)
          }}
          render={onboardingQuestion => (
            <PracticeOnboardingQuestionCard
              practiceId={practiceId}
              practiceOnboardingQuestion={onboardingQuestion}
              onDelete={async onboardingQuestion => {
                await onDeleteOnboardingQuestion(onboardingQuestion)
              }}
            />
          )}
        />
      </Paper>

      <SelectPracticeOnboardingScreenModal
        opened={showModal}
        selectedOnboardingQuestionTypes={practiceOnboardingQuestions.map(
          onboardingQuestion => onboardingQuestion.screen,
        )}
        onCancel={() => setShowModal(false)}
        onSelectOnboardingQuestionType={async practiceOnboardingQuestionType => {
          setShowModal(false)
          await onSelectOnboardingQuestion(practiceOnboardingQuestionType)
        }}
      />
    </>
  )
}
