import { Anchor, Breadcrumbs, Button } from '@mantine/core'
import { useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import ArticlesAPI from '../../../api/articles'
import { AdminArticle, AdminSupport } from '../../../api/schema'
import SupportsAPI from '../../../api/supports'
import Loader from '../../../components/loader'
import ConfirmModal from '../../../components/modal/confirm'
import TitleWithButton from '../../../components/title/TitleWithButton'
import routes from '../../../config/routes'
import useAuthEffect from '../../../hooks/useAuthEffect'

export default function SupportArticlesShowPage() {
  const params = useParams()
  const navigate = useNavigate()
  const [support, setSupport] = useState<AdminSupport | null>(null)
  const [errorFetchingSupport, setErrorFetchingSupport] = useState(false)
  const [article, setArticle] = useState<AdminArticle | null>(null)
  const [errorFetchingArticle, setErrorFetchingArticle] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  async function fetchArticle(id: string) {
    try {
      const article = await ArticlesAPI.show(id)
      setArticle(article)
    } catch (error) {
      setErrorFetchingArticle(true)
    }
  }

  async function fetchSupport(id: string) {
    try {
      const support = await SupportsAPI.show(id)
      setSupport(support)
    } catch (error) {
      setErrorFetchingSupport(true)
    }
  }

  useAuthEffect(() => {
    // eslint-disable-next-line
    fetchArticle(params.articleId!)
    // eslint-disable-next-line
    fetchSupport(params.id!)
  })

  if (errorFetchingArticle) return <div>There was an error fetching the article you requested.</div>
  if (errorFetchingSupport) return <div>There was an error fetching the support you requested.</div>
  if (!article || !support) return <Loader />

  return (
    <div className="page">
      <Breadcrumbs>
        <Anchor component={Link} to={routes.app.supports.index}>
          supports
        </Anchor>
        <Anchor component={Link} to={routes.app.supports.show(support.id)}>
          {support.name}
        </Anchor>
        <Anchor component={Link} to={routes.app.articles.new}>
          {article.name}
        </Anchor>
      </Breadcrumbs>

      <TitleWithButton title={article.name}>
        <Button
          variant="outline"
          component={Link}
          to={article.url}
          target="_blank"
          style={{ marginRight: 10 }}
        >
          preview
        </Button>
        <Button onClick={() => setShowDeleteModal(true)} color="red" variant="outline">
          detach from support
        </Button>
      </TitleWithButton>

      <ConfirmModal
        opened={showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
        onConfirm={async () => {
          await SupportsAPI.detachArticle(support.id, article.id)
          navigate(routes.app.supports.show(support.id))
        }}
        title="Are you sure?"
        message="Are you sure you want to detach this article from this support?"
        confirmText="Really detach?"
      />
    </div>
  )
}
