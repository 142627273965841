import { Modal, Text, TextInput } from '@mantine/core'
import { useEffect, useState } from 'react'
import PhasesAPI, { Phase } from '../../../api/phases'
import { AdminPhase } from '../../../api/schema'
import PhaseCard from '../../card/phase-card'

export default function SelectPhaseModal({
  opened,
  selectedPhaseIds,
  onCancel,
  onSelectPhase,
  title = 'Select a phase',
}: {
  opened: boolean
  selectedPhaseIds: string[]
  onSelectPhase: (phase: AdminPhase) => void
  onCancel: () => void
  title?: string
  confirmText?: string
  cancelText?: string
}) {
  const [query, setQuery] = useState('')
  const [phases, setPhases] = useState<AdminPhase[]>([])

  useEffect(() => {
    async function fetchPhases() {
      setPhases(await PhasesAPI.index({ query }))
    }
    // eslint-disable-next-line
    fetchPhases()
  }, [query])

  const filteredPhases = phases.filter(phase => !selectedPhaseIds.includes(phase.id))

  return (
    <Modal
      className="select-phase-modal"
      fullScreen
      opened={opened}
      onClose={onCancel}
      title={<h3>{title}</h3>}
    >
      <Text size={'md'} mt={20} mb={20}>
        Select a phase to add to your journey
      </Text>
      <TextInput
        id="search-phases-input"
        mb={20}
        value={query}
        onChange={e => setQuery(e.target.value)}
        label="Search"
      />
      {filteredPhases.map((phase, index) => (
        <button
          className="add-phase-btn"
          key={index}
          onClick={() => {
            onSelectPhase(phase)
          }}
        >
          <PhaseCard phase={phase} />
        </button>
      ))}
    </Modal>
  )
}
