import { Anchor, Breadcrumbs, Button, Paper, Table } from '@mantine/core'
import { useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { AdminVideo } from '../../api/schema'
import VideosAPI from '../../api/videos'
import AddOrEditImage from '../../components/add-or-edit-image'
import FormLocalizationSection from '../../components/forms/form-localization-section'
import Loader from '../../components/loader'
import SelectImageModal from '../../components/modal/composite/select-image'
import ConfirmModal from '../../components/modal/confirm'
import TitleWithButton from '../../components/title/TitleWithButton'
import VideoPreview from '../../components/video-preview'
import routes from '../../config/routes'
import useAuthEffect from '../../hooks/useAuthEffect'

export type VideoShowModalType = 'add_image' | 'add_landscape_image' | 'delete' | null

export default function VideosShowPage({ videoId }: { videoId?: string } = {}) {
  const params = useParams()
  const navigate = useNavigate()
  const [video, setVideo] = useState<AdminVideo | null>(null)
  const [modalType, setModalType] = useState<VideoShowModalType | null>(null)
  const [errorFetchingVideo, setErrorFetchingVideo] = useState(false)
  const id = videoId || params.id!

  async function fetchVideo(id: string) {
    try {
      const video = await VideosAPI.show(id)
      setVideo(video)
    } catch (error) {
      setErrorFetchingVideo(true)
    }
  }

  useAuthEffect(() => {
    // eslint-disable-next-line
    fetchVideo(id)
  })

  if (errorFetchingVideo) return <div>There was an error fetching the video you requested.</div>
  if (!video) return <Loader />

  return (
    <div className="page">
      <Breadcrumbs>
        <Anchor component={Link} to={routes.app.videos.index}>
          videos
        </Anchor>
        <Anchor component={Link} to={routes.app.videos.new}>
          {video.name}
        </Anchor>
      </Breadcrumbs>

      <TitleWithButton title={video.name}>
        <Button
          className="outlined blue"
          style={{ marginRight: 10 }}
          component={Link}
          to={routes.app.videos.edit(video.id)}
        >
          edit
        </Button>
        <Button className="outlined red" onClick={() => setModalType('delete')}>
          delete
        </Button>
      </TitleWithButton>

      <Paper mt={20} p={20} mb={20}>
        <Table>
          <tbody>
            <tr>
              <td>duration:</td>
              <td style={{ textAlign: 'right' }}>{video.duration}</td>
            </tr>
            <tr>
              <td>filename:</td>
              <td style={{ textAlign: 'right' }}>{video.filename}</td>
            </tr>
          </tbody>
        </Table>
      </Paper>

      <div style={{ textAlign: 'center' }}>
        <AddOrEditImage
          image={video.image || null}
          onAddOrEditClick={async () => {
            setModalType('add_image')
          }}
          onRemoveClick={async () => {
            try {
              await VideosAPI.update(video.id, { imageId: null })
              await fetchVideo(video.id)
            } catch (err) {
              // TODO: handle this
            }
          }}
        />

        <AddOrEditImage
          landscape
          image={video.landscapeImage || null}
          emptyMessage="no landscape image set"
          addText={'Add landscape image'}
          editText={'Edit landscape image'}
          removeText={'Remove landscape image'}
          onAddOrEditClick={async () => {
            setModalType('add_landscape_image')
          }}
          onRemoveClick={async () => {
            try {
              await VideosAPI.update(video.id, { landscapeImageId: null })
              await fetchVideo(video.id)
            } catch (err) {
              // TODO: handle this
            }
          }}
        />
      </div>
      <VideoPreview url={video.url} />

      <FormLocalizationSection
        apiModule={VideosAPI}
        existingLocalizedTexts={video.localizedTexts}
        localizableId={video.id}
        onChange={async _ => {
          await fetchVideo(video.id)
        }}
        extraFields={['transcription']}
      />

      <ConfirmModal
        opened={modalType === 'delete'}
        onCancel={() => setModalType(null)}
        onConfirm={async () => {
          await VideosAPI.delete(video.id)
          navigate(routes.app.videos.index)
        }}
        title="Are you sure?"
        message="Are you sure you want to delete this video?"
        confirmText="Really delete"
      />

      <SelectImageModal
        opened={modalType === 'add_image'}
        onCancel={() => setModalType(null)}
        onSelectImage={async image => {
          setModalType(null)
          try {
            await VideosAPI.update(video.id, { imageId: image.id })
            await fetchVideo(video.id)
          } catch (err) {
            // TODO: handle this
          }
        }}
      />

      <SelectImageModal
        opened={modalType === 'add_landscape_image'}
        onCancel={() => setModalType(null)}
        onSelectImage={async image => {
          setModalType(null)
          try {
            await VideosAPI.update(video.id, { landscapeImageId: image.id })
            await fetchVideo(video.id)
          } catch (err) {
            // TODO: handle this
          }
        }}
      />
    </div>
  )
}
