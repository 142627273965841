import { Anchor, Button, Paper, Table, Text } from '@mantine/core'
import { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import HumanCoachesAPI from '../../api/humanCoaches'
import { AdminHumanCoach } from '../../api/schema'
import Loader from '../../components/loader'
import TitleWithButton from '../../components/title/TitleWithButton'
import routes from '../../config/routes'
import useAuthEffect from '../../hooks/useAuthEffect'

export default function HumanCoachesIndexPage() {
  const [humanCoaches, setHumanCoaches] = useState<AdminHumanCoach[]>([])
  const [errorFetchingHumanCoach, setErrorFetchingHumanCoach] = useState(false)
  const [doneFetching, setDoneFetching] = useState(false)

  useAuthEffect(() => {
    async function fetchHumanCoaches() {
      try {
        const humanCoaches = await HumanCoachesAPI.index()
        setHumanCoaches(humanCoaches)
        setDoneFetching(true)
      } catch (error) {
        setErrorFetchingHumanCoach(true)
      }
    }

    // eslint-disable-next-line
    fetchHumanCoaches()
  })

  return (
    <div className="page">
      <TitleWithButton title="Human Coaches">
        <Button className="filled green" component={Link} to={routes.app.humanCoaches.new}>
          New human coach
        </Button>
      </TitleWithButton>

      {humanCoaches.length > 0 && (
        <Paper mt={20} p="md">
          <Table>
            <thead>
              <tr>
                <td>Name</td>
              </tr>
            </thead>
            <tbody>
              {humanCoaches.map((humanCoach, index) => (
                <tr key={index}>
                  <td>
                    <Anchor component={NavLink} to={routes.app.humanCoaches.show(humanCoach.id)}>
                      {humanCoach.name}
                    </Anchor>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Paper>
      )}

      {!doneFetching && <Loader />}

      {doneFetching && humanCoaches.length === 0 && (
        <Text style={{ textAlign: 'center' }}>
          <Paper m="lg" p="md">
            No human coaches have been created yet.{' '}
            <Anchor component={Link} to={routes.app.humanCoaches.new}>
              Create one
            </Anchor>{' '}
            to get started.
          </Paper>
        </Text>
      )}
    </div>
  )
}
