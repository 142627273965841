import { Card, Image as MantineImage, Text } from '@mantine/core'
import { ReactNode } from 'react'
import { AdminImage } from '../../api/schema'

export default function ImageCard({
  image,
  onClick,
  width,
  height,
  children,
}: {
  image: AdminImage
  width?: number
  height?: number
  onClick?: () => void | Promise<void>
  children?: ReactNode
}) {
  const card = (
    <Card
      className="image-card"
      title={image.name}
      display="inline-block"
      onClick={onClick as () => void}
      mt={10}
      mb={10}
      padding="sm"
      radius="md"
      withBorder
      style={{ width }}
    >
      <Card.Section>
        <MantineImage src={image.url} width={width} height={height} mr={0} />
      </Card.Section>
      <Card.Section pl={20} pr={20} pt={20} style={{ textAlign: 'center' }}>
        <Text
          style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            fontSize: width && width < 150 ? 12 : undefined,
            fontWeight: 500,
          }}
        >
          {image.name}
        </Text>
      </Card.Section>
      <Card.Section p={20}>{children}</Card.Section>
    </Card>
  )
  return onClick ? <button onClick={onClick as () => void}>{card}</button> : card
}
