import { Anchor, Breadcrumbs, Button, Paper, Table } from '@mantine/core'
import { useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import PhasesAPI from '../../api/phases'
import { AdminPhase } from '../../api/schema'
import AddOrEditImage from '../../components/add-or-edit-image'
import PartsCrud from '../../components/crud/parts-crud'
import FormLocalizationSection from '../../components/forms/form-localization-section'
import Loader from '../../components/loader'
import SelectImageModal from '../../components/modal/composite/select-image'
import ConfirmModal from '../../components/modal/confirm'
import TitleWithButton from '../../components/title/TitleWithButton'
import routes from '../../config/routes'
import useAuthEffect from '../../hooks/useAuthEffect'

type PhaseShowModalType = 'delete' | 'add_image'

export default function PhasesShowPage({ phaseId }: { phaseId?: string } = {}) {
  const params = useParams()
  const navigate = useNavigate()
  const [phase, setPhase] = useState<AdminPhase | null>(null)
  const [errorFetchingPhase, setErrorFetchingPhase] = useState(false)
  const [modalType, setModalType] = useState<PhaseShowModalType | null>(null)
  const id = phaseId || params.id!

  async function fetchPhase(id: string) {
    try {
      const phase = await PhasesAPI.show(id)
      setPhase(phase)
    } catch (error) {
      setErrorFetchingPhase(true)
    }
  }

  useAuthEffect(() => {
    // eslint-disable-next-line
    fetchPhase(id)
  })

  if (errorFetchingPhase) return <div>There was an error fetching the phase you requested.</div>
  if (!phase) return <Loader />

  return (
    <div className="page">
      <Breadcrumbs>
        <Anchor component={Link} to={routes.app.phases.index}>
          phases
        </Anchor>
        <Anchor component={Link} to={routes.app.phases.new}>
          {phase.name}
        </Anchor>
      </Breadcrumbs>

      <TitleWithButton title={phase.name}>
        <Button
          className="outlined blue"
          style={{ marginRight: 10 }}
          component={Link}
          to={routes.app.phases.edit(phase.id)}
        >
          edit
        </Button>
        <Button className="outlined red" onClick={() => setModalType('delete')}>
          delete
        </Button>
      </TitleWithButton>

      <AddOrEditImage
        image={phase.image}
        onAddOrEditClick={async () => {
          setModalType('add_image')
        }}
        onRemoveClick={async () => {
          try {
            await PhasesAPI.update(phase.id, { imageId: null })
            await fetchPhase(phase.id)
          } catch (err) {
            // TODO: handle this
          }
        }}
      />

      <Paper p={20} mt={20}>
        <Table>
          <tbody>
            {[
              <tr key={1}>
                <td>Number of parts:</td>
                <td style={{ textAlign: 'right' }}>{phase.phaseParts.length}</td>
              </tr>,
              <tr key={2}>
                <td>foundation:</td>
                <td style={{ textAlign: 'right' }}>{phase.foundation ? 'true' : 'false'}</td>
              </tr>,
            ]}
          </tbody>
        </Table>
      </Paper>

      <FormLocalizationSection
        apiModule={PhasesAPI}
        existingLocalizedTexts={phase.localizedTexts}
        localizableId={phase.id}
        onChange={async newLocale => {
          await fetchPhase(phase.id)
        }}
        excludedFields={['description', 'summaryDescription']}
      />

      <PartsCrud
        phaseId={phase.id}
        parts={phase.phaseParts.map(pp => pp.part)}
        partShowRouteCB={part => routes.app.phases.parts.show(phase.id, part.id)}
        onSelectPart={async part => {
          try {
            await PhasesAPI.attachPart(phase.id, part.id)
            await fetchPhase(phase.id)
          } catch (err) {
            // TODO: handle this
          }
        }}
      />

      <ConfirmModal
        opened={modalType === 'delete'}
        onCancel={() => setModalType(null)}
        onConfirm={async () => {
          await PhasesAPI.delete(phase.id)
          navigate(routes.app.phases.index)
        }}
        title="Are you sure?"
        message="Are you sure you want to delete this phase?"
        confirmText="Really delete"
      />

      <SelectImageModal
        opened={modalType === 'add_image'}
        onCancel={() => setModalType(null)}
        onSelectImage={async image => {
          setModalType(null)
          try {
            await PhasesAPI.update(phase.id, { imageId: image.id })
            await fetchPhase(phase.id)
          } catch (err) {
            // TODO: handle this
          }
        }}
      />
    </div>
  )
}
