import { Button, Paper } from '@mantine/core'
import { ReactNode, useState } from 'react'
import { AdminLessonResource, AdminSupportLessonResource } from '../../api/schema'
import routes from '../../config/routes'
import LessonResourceCard from '../card/lesson-resource-card'
import EmptyMessage from '../display/empty-message'
import SelectSupportLessonResourceModal from '../modal/composite/select-lesson-resource'
import SortableItems from '../sortable-items'
import TitleWithButton from '../title/TitleWithButton'

export default function SupportLessonResourcesCrud({
  supportLessonResources,
  onSelectLessonResource,
  emptyMessage = 'No lesson resources found',
  onSort,
}: {
  supportLessonResources: AdminSupportLessonResource[]
  onSelectLessonResource: (lessonResource: AdminLessonResource) => void
  emptyMessage?: ReactNode
  onSort: (
    supportLessonResource: AdminSupportLessonResource,
    position: number,
    newLessonResources: AdminSupportLessonResource[],
  ) => void | Promise<void>
}) {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <Paper mt={20} p={20} className="lesson-resources-list">
        <TitleWithButton mb={40} order={2} title="Lesson Resources">
          <Button className="green filled add" onClick={() => setShowModal(true)}>
            Add lesson resource
          </Button>
        </TitleWithButton>

        {!supportLessonResources.length && <EmptyMessage>{emptyMessage}</EmptyMessage>}
        <SortableItems
          itemType="SupportLessonResource"
          items={supportLessonResources}
          onSort={onSort}
          render={supportLessonResource => (
            <LessonResourceCard
              lessonResource={supportLessonResource.lessonResource}
              href={routes.app.supports.lessonResources.show(
                supportLessonResource.supportId,
                supportLessonResource.lessonResource.id,
              )}
            />
          )}
        />
      </Paper>

      <SelectSupportLessonResourceModal
        opened={showModal}
        onCancel={() => setShowModal(false)}
        selectedLessonResourceIds={supportLessonResources.map(
          supportLessonResource => supportLessonResource.lessonResource.id,
        )}
        onSelectLessonResource={lessonResource => {
          setShowModal(false)
          onSelectLessonResource(lessonResource)
        }}
      />
    </>
  )
}
