import { Anchor, Breadcrumbs, Button } from '@mantine/core'
import { useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import ArticlesAPI from '../../../api/articles'
import LessonsAPI from '../../../api/lessons'
import { AdminArticle, AdminLesson } from '../../../api/schema'
import Loader from '../../../components/loader'
import ConfirmModal from '../../../components/modal/confirm'
import TitleWithButton from '../../../components/title/TitleWithButton'
import routes from '../../../config/routes'
import useAuthEffect from '../../../hooks/useAuthEffect'

export default function LessonArticlesShowPage() {
  const params = useParams()
  const navigate = useNavigate()
  const [lesson, setLesson] = useState<AdminLesson | null>(null)
  const [errorFetchingLesson, setErrorFetchingLesson] = useState(false)
  const [article, setArticle] = useState<AdminArticle | null>(null)
  const [errorFetchingArticle, setErrorFetchingArticle] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  async function fetchArticle(id: string) {
    try {
      const article = await ArticlesAPI.show(id)
      setArticle(article)
    } catch (error) {
      setErrorFetchingArticle(true)
    }
  }

  async function fetchLesson(id: string) {
    try {
      const lesson = await LessonsAPI.show(id)
      setLesson(lesson)
    } catch (error) {
      setErrorFetchingLesson(true)
    }
  }

  useAuthEffect(() => {
    // eslint-disable-next-line
    fetchArticle(params.articleId!)
    // eslint-disable-next-line
    fetchLesson(params.id!)
  })

  if (errorFetchingArticle) return <div>There was an error fetching the article you requested.</div>
  if (errorFetchingLesson) return <div>There was an error fetching the lesson you requested.</div>
  if (!article || !lesson) return <Loader />

  return (
    <div className="page">
      <Breadcrumbs>
        <Anchor component={Link} to={routes.app.lessons.index}>
          lessons
        </Anchor>
        <Anchor component={Link} to={routes.app.lessons.show(lesson.id)}>
          {lesson.name}
        </Anchor>
        <Anchor component={Link} to={routes.app.articles.new}>
          {article.name}
        </Anchor>
      </Breadcrumbs>

      <TitleWithButton title={article.name}>
        <Button onClick={() => setShowDeleteModal(true)} color="red" variant="outline">
          detach from lesson
        </Button>
      </TitleWithButton>

      <ConfirmModal
        opened={showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
        onConfirm={async () => {
          await LessonsAPI.detachArticle(lesson.id, article.id)
          navigate(routes.app.lessons.show(lesson.id))
        }}
        title="Are you sure?"
        message="Are you sure you want to detach this article from this lesson?"
        confirmText="Really detach?"
      />
    </div>
  )
}
