import { Modal, Text, TextInput } from '@mantine/core'
import { useEffect, useState } from 'react'
import LessonsAPI from '../../../api/lessons'
import { AdminLesson } from '../../../api/schema'
import LessonCard from '../../card/lesson-card'

export default function SelectLessonModal({
  opened,
  selectedLessonIds,
  onCancel,
  onSelectLesson,
  title = 'Select a lesson',
}: {
  opened: boolean
  selectedLessonIds: string[]
  onSelectLesson: (lesson: AdminLesson) => void
  onCancel: () => void
  title?: string
  confirmText?: string
  cancelText?: string
}) {
  const [query, setQuery] = useState('')
  const [lessons, setLessons] = useState<AdminLesson[]>([])

  useEffect(() => {
    async function fetchLessons() {
      setLessons(await LessonsAPI.index({ query }))
    }

    // eslint-disable-next-line
    fetchLessons()
  }, [query])

  const filteredLessons = lessons.filter(lesson => !selectedLessonIds.includes(lesson.id))

  return (
    <Modal fullScreen opened={opened} onClose={onCancel} title={<h3>{title}</h3>}>
      <Text size={'md'} mt={20} mb={20}>
        Select a lesson to add to your part
      </Text>
      <TextInput
        id="search-lessons-input"
        mb={20}
        value={query}
        onChange={e => setQuery(e.target.value)}
        label="Search"
      />
      {filteredLessons.map((lesson, index) => (
        <button
          className="add-lesson-btn"
          key={index}
          onClick={() => {
            onSelectLesson(lesson)
          }}
        >
          <LessonCard lesson={lesson} />
        </button>
      ))}
    </Modal>
  )
}
