import { useState } from 'react'
import JourneysAPI from '../../api/journeys'
import { useParams } from 'react-router-dom'
import { Anchor, Breadcrumbs, Button, Paper, Table } from '@mantine/core'
import { Link } from 'react-router-dom'
import routes from '../../config/routes'
import TitleWithButton from '../../components/title/TitleWithButton'
import FormLocalizationSection from '../../components/forms/form-localization-section'
import AddOrEditImage from '../../components/add-or-edit-image'
import SelectImageModal from '../../components/modal/composite/select-image'
import useAuthEffect from '../../hooks/useAuthEffect'
import JourneyOnboardingQuestionsCrud from '../../components/crud/journey-onboarding-questions-crud'
import PhasesCrud from '../../components/crud/phases-crud'
import JourneySupportsCrud from '../../components/crud/journey-supports-crud'
import JourneySupportsAPI, { JourneySupport } from '../../api/journeySupports'
import { sortBy } from 'lodash'
import Loader from '../../components/loader'
import JourneyOnboardingQuestionsAPI, {
  JourneyOnboardingQuestion,
} from '../../api/journeyOnboardingQuestions'
import { AdminJourney, AdminJourneySupport, AdminJourneyTrackerQuestion } from '../../api/schema'
import JourneyTrackerQuestionsCrud from '../../components/crud/journey-tracker-questions-crud'
import JourneyTrackerQuestionsAPI from '../../api/journeyTrackerQuestions'

type JourneyShowModalType = 'add_title' | 'add_image'

export default function JourneysShowPage() {
  const params = useParams()
  const [journey, setJourney] = useState<AdminJourney | null>(null)
  const [errorFetchingJourney, setErrorFetchingJourney] = useState(false)
  const [modalType, setModalType] = useState<JourneyShowModalType | null>(null)

  async function fetchJourney(id: string) {
    try {
      const journey = await JourneysAPI.show(id)
      setJourney(journey)
      return journey
    } catch (error) {
      setErrorFetchingJourney(true)
    }
  }

  async function resortJourneySupports(
    journeySupport: AdminJourneySupport,
    position: number,
    newJourneySupports: AdminJourneySupport[],
  ) {
    setJourney({
      ...journey!,
      journeySupports: newJourneySupports,
    })
    await JourneySupportsAPI.update(journeySupport.id, { position })
  }

  async function resortOnboardingQuestions(
    journeyOnboardingQuestion: JourneyOnboardingQuestion,
    position: number,
    newOnboardingQuestions: JourneyOnboardingQuestion[],
  ) {
    setJourney({
      ...journey!,
      onboardingQuestions: newOnboardingQuestions,
    })
    await JourneyOnboardingQuestionsAPI.update(journeyOnboardingQuestion.id, { position })
  }

  async function resortJourneyTrackerQuestions(
    journeyTrackerQuestion: AdminJourneyTrackerQuestion,
    position: number,
    newJourneyTrackerQuestions: AdminJourneyTrackerQuestion[],
  ) {
    setJourney({
      ...journey!,
      journeyTrackerQuestions: newJourneyTrackerQuestions,
    })
    await JourneyTrackerQuestionsAPI.update(journeyTrackerQuestion.id, { position })
  }

  // hack until we can get proper drag and drop tests working
  if (process.env.REACT_APP_PSYCHIC_ENV === 'test') {
    // @ts-ignore
    window.__testOnlySimulateSupportDragAndDrop = resortJourneySupports
    // @ts-ignore
    window.__testOnlySimulateOnboardingQuestionDragAndDrop = resortOnboardingQuestions
    // @ts-ignore
    window.__testOnlySimulateTrackerQuestionDragAndDrop = resortJourneyTrackerQuestions
  }

  useAuthEffect(() => {
    async function fetchAndSetDefaultLocales() {
      await fetchJourney(params.id!)
    }
    // eslint-disable-next-line
    fetchAndSetDefaultLocales()
  })

  if (errorFetchingJourney) return <div>There was an error fetching the journey you requested.</div>
  if (!journey) return <Loader />

  const journeySupports = sortBy(journey.journeySupports, 'position')
  const onboardingQuestions = sortBy(journey.onboardingQuestions, 'position')

  return (
    <div className="page">
      <Breadcrumbs>
        <Anchor component={Link} to={routes.app.journeys.index}>
          journeys
        </Anchor>
        <Anchor component={Link} to={routes.app.journeys.show(journey.id)}>
          {journey.name}
        </Anchor>
      </Breadcrumbs>

      <TitleWithButton
        title={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div>{journey.name}</div>
            <div
              className={`chip ${journey.published ? 'green' : 'yellow'}`}
              style={{
                display: 'inline-block',
                marginLeft: 10,
                marginTop: 3,
              }}
            >
              {journey.published ? 'Published' : 'Unpublished'}
            </div>
          </div>
        }
      >
        <Button
          className="outlined blue"
          style={{ marginRight: 10 }}
          component={Link}
          to={routes.app.journeys.edit(journey.id)}
        >
          edit
        </Button>
      </TitleWithButton>

      <AddOrEditImage
        image={journey.image}
        onAddOrEditClick={() => {
          setModalType('add_image')
        }}
        onRemoveClick={async () => {
          try {
            await JourneysAPI.update(journey.id, { imageId: null })
            await fetchJourney(journey.id)
          } catch (err) {
            // TODO: handle this
          }
        }}
      />

      <Paper p={20} mt={20} mb={20}>
        <Table>
          <tbody>
            {[
              <tr key={1}>
                <td>Standard name:</td>
                <td style={{ textAlign: 'right' }}>{journey.standardName as string}</td>
              </tr>,
            ]}
          </tbody>
        </Table>
      </Paper>

      <FormLocalizationSection
        apiModule={JourneysAPI}
        existingLocalizedTexts={journey.localizedTexts}
        localizableId={journey.id as string}
        onChange={async _ => {
          await fetchJourney(journey.id as string)
        }}
        excludedFields={['summaryDescription']}
      />

      <PhasesCrud
        phases={journey.journeyPhases.map(jp => jp.phase)}
        phaseShowRouteCB={phase => routes.app.journeys.phases.show(journey.id, phase.id)}
        onSelectPhase={async phase => {
          try {
            await JourneysAPI.attachPhase(journey.id, phase.id)
            await fetchJourney(journey.id)
          } catch (err) {
            // TODO: handle this
          }
        }}
      />

      <JourneySupportsCrud
        journeySupports={journeySupports}
        supportShowRouteCB={support => routes.app.journeys.supports.show(journey.id, support.id)}
        onSelectSupport={async support => {
          try {
            await JourneysAPI.attachSupport(journey.id, support.id)
            await fetchJourney(journey.id)
          } catch (err) {
            // TODO: handle this
          }
        }}
        onSort={resortJourneySupports}
      />

      <JourneyOnboardingQuestionsCrud
        journeyId={journey.id}
        journeyOnboardingQuestions={onboardingQuestions}
        onDeleteOnboardingQuestion={async () => {
          await fetchJourney(journey.id)
        }}
        onSelectOnboardingQuestion={async onboardingQuestionType => {
          try {
            await JourneysAPI.createOnboardingQuestion(journey.id, { screen: onboardingQuestionType })
            await fetchJourney(journey.id)
          } catch (err) {
            // TODO: handle this
          }
        }}
        onSort={resortOnboardingQuestions}
      />

      <JourneyTrackerQuestionsCrud
        journeyId={journey.id}
        journeyTrackerQuestions={journey.journeyTrackerQuestions}
        onDeleteTrackerQuestion={async () => {
          await fetchJourney(journey.id)
        }}
        onSelectTrackerQuestion={async trackerQuestion => {
          await JourneysAPI.createJourneyTrackerQuestion(journey.id, {
            trackerQuestionId: trackerQuestion.id,
          })
          await fetchJourney(journey.id)
        }}
        onSort={resortJourneyTrackerQuestions}
      />

      <SelectImageModal
        opened={modalType === 'add_image'}
        onCancel={() => setModalType(null)}
        onSelectImage={async image => {
          setModalType(null)
          try {
            await JourneysAPI.update(journey.id, { imageId: image.id })
            await fetchJourney(journey.id)
          } catch (err) {
            // TODO: handle this
          }
        }}
      />
    </div>
  )
}
