import { Anchor, Breadcrumbs, Button, Paper, Table } from '@mantine/core'
import { useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import ImagesAPI from '../../api/images'
import { AdminImage } from '../../api/schema'
import FormLocalizationSection from '../../components/forms/form-localization-section'
import ImagePreview from '../../components/image-preview'
import Loader from '../../components/loader'
import ConfirmModal from '../../components/modal/confirm'
import TitleWithButton from '../../components/title/TitleWithButton'
import routes from '../../config/routes'
import useAuthEffect from '../../hooks/useAuthEffect'

export default function ImagesShowPage({ imageId }: { imageId?: string } = {}) {
  const params = useParams()
  const navigate = useNavigate()
  const [image, setImage] = useState<AdminImage | null>(null)
  const [errorFetchingImage, setErrorFetchingImage] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const id = imageId || params.id!

  async function fetchImage(id: string) {
    try {
      const image = await ImagesAPI.show(id)
      setImage(image)
    } catch (error) {
      setErrorFetchingImage(true)
    }
  }

  useAuthEffect(() => {
    // eslint-disable-next-line
    fetchImage(id)
  })

  if (errorFetchingImage) return <div>There was an error fetching the image you requested.</div>
  if (!image) return <Loader />

  return (
    <div className="page">
      <Breadcrumbs>
        <Anchor component={Link} to={routes.app.images.index}>
          images
        </Anchor>
        <Anchor component={Link} to={routes.app.images.new}>
          {image.name}
        </Anchor>
      </Breadcrumbs>

      <TitleWithButton title={image.name}>
        <Button
          className="outlined blue"
          style={{ marginRight: 10 }}
          component={Link}
          to={routes.app.images.edit(image.id)}
        >
          edit
        </Button>
        <Button className="outlined red" onClick={() => setShowDeleteModal(true)}>
          delete
        </Button>
      </TitleWithButton>

      <Paper mt={20} p={20} mb={20}>
        <Table>
          <tbody>
            <tr>
              <td>filename:</td>
              <td style={{ textAlign: 'right' }}>{image.filename}</td>
            </tr>
          </tbody>
        </Table>
      </Paper>

      <ImagePreview url={image.url} />

      <FormLocalizationSection
        apiModule={ImagesAPI}
        existingLocalizedTexts={image.localizedTexts}
        localizableId={id}
        onChange={async _ => {
          await fetchImage(id)
        }}
      />

      <ConfirmModal
        opened={showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
        onConfirm={async () => {
          await ImagesAPI.delete(image.id)
          navigate(routes.app.images.index)
        }}
        title="Are you sure?"
        message="Are you sure you want to delete this image?"
        confirmText="Really delete"
      />
    </div>
  )
}
